import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Card, Input, CardContent, Dialog, Slide } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';

export default function AddCategory() {
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [category, setCategory] = React.useState({ categoryname: "", title: "", description: "" })
    const [error, setError] = React.useState({ categoryname: "", title: "" })
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [imageError, setImageError] = React.useState('')
	const [truebutton, setTruebutton] = React.useState(false)

    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")  
    }

    let name, value;
    const handleCategory = (e) => {
        name = e.target.name;
        value = e.target.value
        setCategory({ ...category, [name]: value })
    }
    const Validator = () => {
        if (!category.categoryname && !category.title && statesingle2?.length == 0) {
            setError({ categoryname: "This field is required.", title: "This field is required." })
            setImageError("This field is required.")
            return false
        }
        if (!category.categoryname) {
            setError({ categoryname: "This field is required." })
            return false
        }
        if (!category.title) {
            setError({ title: "This field is required." })
            return false
        }
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        return true
    }

    const handleSubmit = async () => {
        var multipleImage1 = statesingle2.filter(x => x != null);
        var Valid = Validator()
        if (Valid) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("category_name", category.categoryname)
            formData.append("category_title", category.title)
            formData.append("description", category.description)
            { multipleImage1 ? multipleImage1.forEach((item) => { formData.append("image", item) }) : formData.append("image", '') }
            await axios.post(process.env.REACT_APP_BASE_URL + `category/addCategory`, formData, { headers: headerformdata },)
                .then((res) => {
                    setTruebutton(false)

                    navigate('/category')
                    enqueueSnackbar("Category added successfully", { variant: 'success' })

                })
                .catch((error) => {
                    setTruebutton(false)

                    enqueueSnackbar("Something went wrong.", { variant: 'error' })
                    console.log(error.response, "erroe");
                })
        }
    }

    const multipleImgChange1 = (e, index) => {
        setImageError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        var data = statesingle.filter(x => x != null)
        var data1 = statesingle2.filter(x => x != null)
        setStatesingle(data)
        setStatesingle2(data1)
        setStatesingle(prevValue => prevValue.concat(fileArray))
        setStatesingle2(prevValue => prevValue.concat(filepath))
    }
    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    return (
        <Box className=''>
            <MiniDrawer type={1} />
            <Box component="section" className="contentWraper">

                <Grid container rowSpacing={5} columnSpacing={3}>

                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className='whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add Category</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Category Name <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Category Name'
                                                name="categoryname"
                                                value={category.categoryname}
                                                onChange={(e) => { handleCategory(e); setError({ categoryname: "" }) }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {/* {error.categoryname && <p style={{ color: 'red' }} >{error.categoryname}</p>} */}
                                            {error.categoryname && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.categoryname}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>

                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Title'
                                                name="title"
                                                value={category.title}
                                                onChange={(e) => { handleCategory(e); setError({ title: "" }) }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {/* {error.title && <p style={{ color: 'red' }} >{error.title}</p>} */}
                                            {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                            <label className='form-labels ' for="my-input" >Image<Typography component='span' className='star'> * </Typography></label>
                                            {statesingle && statesingle.length === 0 &&
                                                <>
                                                    <label htmlFor="contained-button-file1" className="upload_btn">
                                                        <Box className='' >
                                                            <Input
                                                                inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                                onChange={multipleImgChange1}
                                                                style={{ display: "none", }}
                                                                id="contained-button-file1"
                                                                type="file"
                                                            />
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                        </Box>
                                                    </label>
                                                </>
                                            }
                                        </Box>
                                    </Grid>
                                    {/* {imageError && <p style={{ color: 'red',marginLeft:20 }} >{imageError}</p>} */}
                                    {imageError&& <p style={{ color: 'red' }} className="fz-13 ml-20" >{imageError}</p>}
                                  
                                  
                                  
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                    {statesingle && statesingle.length > 0 ? statesingle.map((x, i) => (
                                        <Box className='uploaded_img mb-10' >
                                            <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                            <img src={x} alt='' height={155} width={195} />
                                        </Box>
                                    )) : ""}
                                    <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 400px * 400px for better quality picture.</Typography>

                                    </Grid>
                               


                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description</Typography>
                                            <textarea
                                                className='textarea'
                                                style={{ width: '100%' }}
                                                placeholder='Description'
                                                name="description"
                                                value={category.description}
                                                onChange={(e) => handleCategory(e)}
                                                rows={4}
                                                cols={40} />
                                           
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {truebutton == false ? 
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button onClick={handleSubmit} variant="contained" className='theme-btn mr-10'>Submit</Button>
                                </Box>
                                :
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                <Button variant="contained" className='theme-btn mr-10'>Please wait...</Button>
                            </Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}

