import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Grid, Button, TextField, InputAdornment, FormControl, Card, Menu } from '@mui/material';
import { PlaylistAddRounded, Add, SearchOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack'
import MiniDrawer from '../../../components/drawer'
import Switch, { SwitchProps } from '@mui/material/Switch';
import CardContent from '@mui/material/CardContent';
import DialogContent from '@mui/material/DialogContent';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(24px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function Gallery() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);
    const [deleteid, setDeleteId] = React.useState('');
    const [openview, setOpenview] = React.useState(false);
    const [alldata, setAlldata] = React.useState('');
    const [search, setSearch] = React.useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    const handleClose_menu = () => {
        setAnchorEl(null);
    };


    React.useEffect(() => {
        getGallery()
    }, [rowsPerPage, page, search])

    const getGallery = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `gallery/getGallery?size=${rowsPerPage}&page=${page}`, { headers: headerlocal })
            .then((res) => {
                console.log(res.data, "ressssssssssssssssss");
                setNewdata(res.data.getGallerydata)
                setTotal(res.data.totalcount)
            }).catch((error) => {
                console.log(error, "error in service get");
            })
    }

    const handleDeleteGallery = async () => {
        // await axios.put(process.env.REACT_APP_BASE_URL + `team/DeleteTeam/${deleteid}`, {}, { headers: headerlocal })
        await axios.put(process.env.REACT_APP_BASE_URL + `gallery/DeleteGallery/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                enqueueSnackbar("Gallery deleted successfully", { variant: 'success' })
                getGallery()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleNavigate = () => {
        navigate('/addgallery')
    }



    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };


    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate("/editgallery", { state: val })
    }

    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }


    const handleView = () => {
        setAnchorEl(null);
        setOpenview(true)

    }


    return (
        <>
            <Box className=''>
                <MiniDrawer type={4} />
                <Box component="section" className="contentWraper">
                    <Box className='headingBox'>
                        <Typography component='h6' className='formTitle'>Gallery</Typography>
                        <Button variant="contained" className='theme-btn' onClick={(e) => handleNavigate()}> <PlaylistAddRounded className='fz-18 mr-5' />Add Gallery</Button>
                    </Box>


                    <Box className='whiteContainer p-20'>
                        <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList" >
                            {newdata && newdata.length > 0 ? newdata.map((data, index) => (
                                console.log(data, "data"),
                                <Grid key={index} item xs={12} sm={6} md={4} lg={3} sx={{ marginTop: 2 }}>
                                    <Card className="cardGrid" >
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={menuopen ? 'long-menu' : undefined}
                                            aria-expanded={menuopen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, data)}
                                            className='vertical_menu'
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={menuopen}
                                            onClose={handleClose_menu}
                                            PaperProps={{
                                                style: {
                                                    width: '15ch',
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

                                        >
                                            <MenuItem className='menuitems' onClick={() => handleView()}><VisibilityIcon className='fz-18' style={{ margin: 5 }} />View</MenuItem>

                                            <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-18' style={{ margin: 5 }} />Edit</MenuItem>
                                            <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}><DeleteIcon className='fz-18' style={{ margin: 5 }} />Delete</MenuItem>
                                        </Menu>
                                        <div className="carosuel">
                                            <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${data.image}`} />
                                            {/* <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${data.image}`} />       */}
                                        </div>
                                        <CardContent>
                                            <Typography noWrap className='semibold title' variant="h3" component="h3">{data.title}</Typography>
                                            {/* <Typography className='p_tag' component='p'>{data.email}</Typography> */}


                                        </CardContent>
                                    </Card>
                                </Grid>
                            )) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                <p className='text-center semibold'>No data found</p></Grid>}
                        </Grid>
                        <Dialog open={open} keepMounted onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                            <DialogTitle>Would you like to delete gallery?</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleClose} className='cancel_btn'>No</Button>
                                <Button onClick={handleDeleteGallery} className='theme-btn'>Yes</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                    <TablePagination
                        rowsPerPageOptions={[8, 12, 16, 20, 24]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='DT-pagination'
                    />
                </Box>
            </Box>

            <BootstrapDialog onClose={handleClickClose} aria-labelledby="customized-dialog-title" open={openview} className='custom_modal' maxWidth='md'>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Gallery View</DialogTitle>
                <DialogContent dividers>
                    <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10 align-items-center">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="carosuel mb-10">
                                <Swiper
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper "
                                    slidesPerView={1}
                                    pagination={{
                                        clickable: true,
                                    }}
                                >
                                    {alldata && alldata.allimages && alldata.allimages?.length > 0 && alldata.allimages.map((tdata, i) => (
                                        <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                                    )
                                    )}
                                </Swiper>
                            </div>

                        </Grid>


                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}