import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, FormControl, InputAdornment, TextField, Grid } from '@mui/material';
import MiniDrawer from '../../../../components/drawer';
import { Navigate, useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import axios from 'axios';
import { useSnackbar } from 'notistack'

export default function Brochure() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    let navigate = useNavigate()
    const [contactdetails, setContactDetails] = React.useState([{ addresstype: "", address: "", phone: "", email: "", googlemap: "", facebook: "", instagram: "", linkedin: "", twitter: "", selected: true }])


    const [errorind, setIndex] = React.useState('')
    const [buttonview, setButton] = React.useState(false)


    const [error, setError] = React.useState({ addresstype: "", address: "", phone: "", email: "", google: "", facebook: "", instagram: "", linkedin: "", twitter: "" })
    const { enqueueSnackbar } = useSnackbar()


    const regex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    const regexemail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regexmap = /^https?\:\/\/(www\.)?google\.[a-z]+\/maps\b/

    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') };

    const handleAddClick = (i) => {
        if (contactdetails[i].addresstype == '' || contactdetails[i].address == '' || contactdetails[i].phone == '' || contactdetails[i].email == '') {
            setIndex(i)
            setError({ addresstype: "This field is required.", address: "This field is required.", phone: "This field is required.", email: "This field is required." })
            return false
        }

        else if (regexemail.test(contactdetails[i].email) === false) {
            setIndex(i)
            setError({ email: "Please enter valid email address" })
            return false
        }


        // if (regexmap.test(contactdetails[i].googlemap) === false) {
        //     setIndex(i)
        //     setError({ google: "Please enter valid URL." })
        //     return false
        // }
        // else if (contactdetails[i].facebook) {
        //           if (regex.test(contactdetails[i].facebook) == false) {
        //         setIndex(i)
        //         setError({ facebook: "Please enter valid URL." })
        //         return false
        //     }

        // }

        // else if (contactdetails[i].instagram) {
        //     console.log(regex.test(contactdetails[i].instagram),"5555");
        //     if (regex.test(contactdetails[i].instagram) == false) {
        //         setIndex(i)
        //         setError({ instagram: "Please enter valid URL." })
        //         return false
        //     }

        // }

        // else if (contactdetails[i].linkedin) {
        //     console.log(regex.test(contactdetails[i].linkedin),"6666");
        //     if (regex.test(contactdetails[i].linkedin) == false) {
        //         setIndex(i)
        //         setError({ linkedin: "Please enter valid URL." })
        //         return false
        //     }

        // }

        // if (contactdetails[i].twitter) {
        //     console.log(regex.test(contactdetails[i].twitter) == false,"7777");
        //     if (regex.test(contactdetails[i].twitter) == false) {
        //         setIndex(i)
        //         setError({ twitter: "Please enter valid URL." })
        //         return false
        //     }

        // }
        else {
            setContactDetails([...contactdetails, { addresstype: "", address: "", phone: "", email: "", googlemap: "", facebook: "", instagram: "", linkedin: "", twitter: "", selected: false }]);

        }



    };



    const handleAddNewClick = () => {
        if (contactdetails.some(data => data.addresstype == '')) {
            let abc = contactdetails.findIndex(data => data.addresstype == '')
            setIndex(abc)
            setError({ addresstype: "This field is required." })
            return false
        }

        if (contactdetails.some(data => data.address == '')) {
            let abc = contactdetails.findIndex(data => data.address == '')
            setIndex(abc)
            setError({ address: "This field is required." })
            return false
        }

        if (contactdetails.some(data => data.phone == '')) {
            let abc = contactdetails.findIndex(data => data.phone == '')
            setIndex(abc)
            setError({ phone: "This field is required." })
            return false
        }

        if (contactdetails.some(data => data.email == '')) {
            let abc = contactdetails.findIndex(data => data.email == '')
            setIndex(abc)
            setError({ email: "This field is required." })
            return false
        }
        else{
            setContactDetails([...contactdetails, { addresstype: "", address: "", phone: "", email: "", googlemap: "", facebook: "", instagram: "", linkedin: "", twitter: "", selected: true }]);
        }
        return true
    }




    React.useEffect(() => {
        getDetails()
    }, [])

    const getDetails = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `admincontact/getAdmindetails`, { headers: headerlocal })
            .then((res) => {
                setContactDetails(res.data.admindetails.contactdetails)
            })
            .catch((err) => {
                console.log(err, "error");
            })

    }



    const handleContact = (e, index) => {
        const { name, value } = e.target;
        const list = [...contactdetails];
        list[index][name] = value;
        setContactDetails(list);
    }

    const handleRemoveClick = (index) => {
        const list = [...contactdetails];
        list.splice(index, 1);
        setContactDetails(list);
    };




    const Validation = () => {
        if (contactdetails && contactdetails?.length == 0) {
            enqueueSnackbar("Please add contact details", { variant: 'error' })
            return false
        }
        if (contactdetails.some(data => data.addresstype == '')) {
            let abc = contactdetails.findIndex(data => data.addresstype == '')
            setIndex(abc)
            setError({ addresstype: "This field is required." })
            return false
        }

        if (contactdetails.some(data => data.address == '')) {
            let abc = contactdetails.findIndex(data => data.address == '')
            setIndex(abc)
            setError({ address: "This field is required." })
            return false
        }

        if (contactdetails.some(data => data.phone == '')) {
            let abc = contactdetails.findIndex(data => data.phone == '')
            setIndex(abc)
            setError({ phone: "This field is required." })
            return false
        }

        if (contactdetails.some(data => data.email == '')) {
            let abc = contactdetails.findIndex(data => data.email == '')
            setIndex(abc)
            setError({ email: "This field is required." })
            return false
        }
        return true
    }

    const handleContactSubmit = async () => {
        let Valid = Validation()
        if (Valid) {
            setButton(true)
            var body = {
                contactdetails: contactdetails
            }
            await axios.post(process.env.REACT_APP_BASE_URL + `admincontact/AdminCotact`, body, { headers: headerlocal })
            .then((res) => {
                setButton(false)
                enqueueSnackbar("Contact details updated successfully", { variant: 'success' })
                getDetails()
            }).catch((error) => {
                setButton(false)
                enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                console.log(error, "error");
            })
        }
    }

    return (
        <Box className=''>
            <MiniDrawer type={5} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h6' className='formTitle'>The Impression Contact Details</Typography>
                </Box>

                <Box className='whiteContainer'>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={12} sm={12} md={12} className='d-flex justify-content-end mt-20 mr-10' >

                            <Button className='theme-btn ml-5' onClick={() => handleAddNewClick()} >Add</Button>
                        </Grid>
                        {contactdetails && contactdetails?.length > 0 && contactdetails.map((val, i) => (
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                <Box className='content p-20'>

                                    <Grid container rowSpacing={2} columnSpacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Box className='headingBox'>
                                                <Typography component='h6' className='primary_text fz-16 semibold'>Contact Details {i + 1}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Address type <Typography component='span' className='star'>*</Typography></Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    className='textfield'
                                                    placeholder='Address type'
                                                    name="addresstype"
                                                    value={val.addresstype}
                                                    onChange={(e) => { handleContact(e, i); setError({ addresstype: "" }) }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                                {error.addresstype && i == errorind && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.addresstype}</p>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Address <Typography component='span' className='star'>*</Typography></Typography>
                                                <textarea
                                                    // className='textfield'
                                                    name="address"
                                                    className='textarea'
                                                    style={{ width: '100%' }}
                                                    value={val.address}
                                                    onChange={(e) => { handleContact(e, i); setError({ address: "" }) }}
                                                    rows={8}
                                                    cols={40} />
                                                {error.address && i == errorind && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.address}</p>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Phone <Typography component='span' className='star'>*</Typography></Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    className='textfield'
                                                    placeholder='Phone'
                                                    // onInput={(e) => onlyNumbers(e)}
                                                    name="phone"
                                                    value={val.phone}
                                                    onChange={(e) => { handleContact(e, i); setError({ phone: "" }) }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                                {error.phone && i == errorind && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.phone}</p>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Email <Typography component='span' className='star'>*</Typography></Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    className='textfield'
                                                    placeholder='Email'
                                                    name="email"
                                                    value={val.email}
                                                    onChange={(e) => { handleContact(e, i); setError({ email: "" }) }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                                {error.email && i == errorind && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.email}</p>}
                                            </FormControl>
                                        </Grid>



                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Googlemap Link </Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    className='textfield'
                                                    placeholder='Googlemap Link'
                                                    name="googlemap"
                                                    value={val.googlemap}
                                                    onChange={(e) => { handleContact(e, i); setError({ google: "" }) }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />

                                                {error.google && i == errorind && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.google}</p>}
                                            </FormControl>
                                        </Grid>





                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Facebook Link</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    className='textfield'
                                                    placeholder='Facebook Link'
                                                    name="facebook"
                                                    value={val.facebook}
                                                    onChange={(e) => { handleContact(e, i); setError({ facebook: "" }) }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                                {error.facebook && i == errorind && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.facebook}</p>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Instagram Link</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    className='textfield'
                                                    placeholder='Instagram Link'
                                                    name="instagram"
                                                    value={val.instagram}
                                                    onChange={(e) => { handleContact(e, i); setError({ instagram: "" }) }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                                {error.instagram && i == errorind && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.instagram}</p>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Linkedin Link</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    className='textfield'
                                                    placeholder='Linkedin Link'
                                                    name="linkedin"
                                                    value={val.linkedin}
                                                    onChange={(e) => { handleContact(e, i); setError({ linkedin: "" }) }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                                {error.linkedin && i == errorind && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.linkedin}</p>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Twitter Link</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    className='textfield'
                                                    placeholder='Twitter Link'
                                                    name="twitter"
                                                    value={val.twitter}
                                                    onChange={(e) => { handleContact(e, i); setError({ twitter: "" }) }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                                {error.twitter && i == errorind && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.twitter}</p>}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} className='d-flex justify-content-end' >

                                            {i >= 0 ?
                                                <Button className='theme-btn' onClick={() => handleRemoveClick(i)} >Delete</Button>
                                                : ""}
                                            {/* {contactdetails?.length - 1 === i &&
                                                <Button className='theme-btn ml-5' onClick={() => handleAddClick(i)} >Add</Button>
                                            } */}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        ))}

                        {/* {contactdetails?.length === 0 && */}
                        {/* <Grid item xs={12} sm={12} md={12} className='d-flex justify-content-end mt-20 mr-10' >

                                <Button className='theme-btn ml-5' onClick={() => handleAddNewClick()} >Add</Button>
                            </Grid> */}
                        {/* } */}

                        <Grid item xs={12} sm={12} md={12} className='my-30'>

                            {buttonview == false ?
                                <Box className='heading-btns ' style={{ textAlign: 'end' }}>
                                    <Button onClick={handleContactSubmit} variant="contained" className='theme-btn mr-10 '>Update</Button>
                                </Box>
                                :
                                <Box className='heading-btns ' style={{ textAlign: 'end' }}>
                                    <Button variant="contained" className='theme-btn mr-10'>Please wait...</Button>
                                </Box>}
                        </Grid>
                    </Grid>

                </Box>
            </Box >
        </Box >
    )
}