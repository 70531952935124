import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Card, Input, CardContent, Dialog, Slide } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';

export default function AddGallery() {
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [category, setCategory] = React.useState({ title: "" })
    const [error, setError] = React.useState({ title: "" })
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [imageError, setImageError] = React.useState('')
    const [truebutton, setTruebutton] = React.useState(false)
    const [imageAddError, setImageAddError] = React.useState('')
    const [state1, setState1] = React.useState([])
    const [state2, setState2] = React.useState([])

    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }

    let name, value;
    const handleCategory = (e) => {
        name = e.target.name;
        value = e.target.value
        setCategory({ ...category, [name]: value })
    }
    const Validator = () => {
        if (!category.title && statesingle2?.length == 0 && state2?.length == 0) {
            setError({ title: "This field is required." })
            setImageError("This field is required.")
            setImageAddError("This field is required.")
            return false
        }

        if (!category.title) {
            setError({ title: "This field is required." })
            return false
        }
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }

        if (state2?.length == 0) {
            setImageAddError("This field is required.")
            return false
        }




        return true
    }



    const handleSubmit = async () => {
        var multipleImage1 = statesingle2.filter(x => x != null);
        var multipleImage = state2.filter(x => x != null);

        var Valid = Validator()
        if (Valid) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("title", category.title)
            { multipleImage1 ? multipleImage1.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
            { multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '') }
            await axios.post(process.env.REACT_APP_BASE_URL + `gallery/addGallery`, formData, { headers: headerformdata },)
                .then((res) => {
                    setTruebutton(false)

                    navigate('/gallery')
                    enqueueSnackbar("Gallery added successfully", { variant: 'success' })

                })
                .catch((error) => {
                    setTruebutton(false)

                    enqueueSnackbar("Something went wrong.", { variant: 'error' })
                    console.log(error.response, "erroe");
                })
        }
    }

    const SingleImgChange = (e, index) => {
        console.log("6666666");
        setImageError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        var data = statesingle.filter(x => x != null)
        var data1 = statesingle2.filter(x => x != null)
        setStatesingle(data)
        setStatesingle2(data1)
        setStatesingle(prevValue => prevValue.concat(fileArray))
        setStatesingle2(prevValue => prevValue.concat(filepath))
    }
    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }



    const multipleImgChange = (e, index) => {
        console.log("88888888888");
        setImageAddError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        var data = state1.filter(x => x != null)
        var data1 = state2.filter(x => x != null)
        setState1(data)
        setState2(data1)
        setState1(prevValue => prevValue.concat(fileArray))
        setState2(prevValue => prevValue.concat(filepath))
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }


    return (
        <Box className=''>
            <MiniDrawer type={4} />
            <Box component="section" className="contentWraper">

                <Grid container rowSpacing={5} columnSpacing={3}>

                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className='whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add Gallery</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>

                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Title'
                                                name="title"
                                                value={category.title}
                                                onChange={(e) => { handleCategory(e); setError({ title: "" }) }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                            <label className='form-labels ' for="my-input" >Image<Typography component='span' className='star'> * </Typography></label>
                                            {statesingle && statesingle.length === 0 &&
                                                <>
                                                    <label htmlFor="contained-button-file1" className="upload_btn">
                                                        <Box className='' >
                                                            <Input
                                                                inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                                onChange={SingleImgChange}
                                                                style={{ display: "none", }}
                                                                id="contained-button-file1"
                                                                type="file"
                                                            />
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                        </Box>
                                                    </label>
                                                </>
                                            }
                                        </Box>
                                    </Grid>
                                    {imageError && <p style={{ color: 'red' }} className="fz-13 ml-20" >{imageError}</p>}



                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        {statesingle && statesingle.length > 0 ? statesingle.map((x, i) => (
                                            <Box className='uploaded_img mb-10' >
                                                <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                <img src={x} alt='' height={155} width={195} />
                                            </Box>
                                        )) : ""}
                                        
                                        <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 400px * 400px for better quality picture.</Typography>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}  >
									<label className='form-labels ' for="my-input" >Additional Images<Typography component='span' className='star'> * </Typography></label>
									<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
										{state1.length < 1 &&
											<Button className="multiple_upload_btn" variant="contained" component="label">
												<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
												<input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden multiple type="file" />
											</Button>
										}
									</Box>
								</Grid>

								{imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0 ml-20" >{imageAddError}</p>}


								<Grid item xs={12} sm={12} md={12}  >
									{state1.length > 0 ?
										<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
											{state1.length > 0 ? state1.map((d, i) => (
                                          
												<Grid item xs={6} sm={4} md={4} lg={4} >
													<Box className='uploaded_img mb-10' >
														<Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                                                        <img src={d.slice(0, 4) === "blob" ? d : process.env.REACT_APP_BASE_URL + `${d}`} alt='' height={155} width={195} />

													</Box>
												</Grid>
											)) : ""}

											<Grid item xs={6} sm={4} md={4} lg={4} >
												<label htmlFor="contained-button-file1" className="w-full">
													<Box className='multiple_upload_btn' >
														<Input
															inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
															onChange={multipleImgChange}
															style={{ display: "none", }}
															id="contained-button-file1"
															type="file"
														/>
														<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
													</Box>
												</label>
											</Grid>
										</Grid>
									:""}
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
								</Grid>

                                    {/* <Grid item xs={12} sm={12} md={12}  >
                                        <label className='form-labels ' for="my-input" >Additional Images </label>
                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                            {state1.length < 1 &&
                                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                    <input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden multiple type="file" />
                                                </Button>
                                            }
                                        </Box>
                                    </Grid>

                                    

                                    {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0 ml-20" >{imageAddError}</p>}


                                    <Grid item xs={12} sm={12} md={12}  >
                                        {state1.length > 0 &&
                                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                           
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    {state1 && state1.length > 0 ? state1.map((x, i) => (
                                                    <Grid item xs={6} sm={4} md={4} lg={4} >
                                                        <Box className='uploaded_img mb-10' >
                                                            <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                                                            <img src={x} alt='' height={155} width={195} />
                                                        </Box>
                                                        </Grid>
                                                        
                                                    )) : ""}

                                                </Grid>



                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                    <label htmlFor="contained-button-file1" className="w-full">
                                                        <Box className='multiple_upload_btn' >
                                                            <Input
                                                                inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                                onChange={multipleImgChange}
                                                                style={{ display: "none", }}
                                                                id="contained-button-file1"
                                                                type="file"
                                                            />
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
                                                        </Box>
                                                    </label>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1000px * 1920px for better quality picture.</Typography>
                                    </Grid> */}

                                </Grid>

                                {truebutton == false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button onClick={handleSubmit} variant="contained" className='theme-btn mr-10'>Submit</Button>
                                    </Box>
                                    :
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='theme-btn mr-10'>Please wait...</Button>
                                    </Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}

