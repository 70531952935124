import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { Button, List, ListItem, ListItemText, InputAdornment, IconButton, MenuList, Popper, TextField, Grid, Card, Divider, Modal } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack'
import DialogContent from '@mui/material/DialogContent';
import MiniDrawer from '../../../../components/drawer'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ArrowRightAlt, FlashOnRounded } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import userimage from '../../../../assets/images/user.png'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

export default function Career() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }

    const auth = localStorage.getItem("userData")
    const { enqueueSnackbar } = useSnackbar()
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [total, setTotal] = React.useState(0);
    const [rows, setRows] = React.useState([]);

    const [open, setOpen] = React.useState(false);
    const [viewData, setViewData] = React.useState('');

    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    let navigate = useNavigate()
    //Modal
    const [modalopen, setModalOpen] = React.useState(false);
    const [showPosition, setshowPosition] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [items, setItems] = React.useState([]);
    const [error, setError] = React.useState({ positionsmsg: '' })
    const [designation,setDesignation] = React.useState([]);
    //Deletealert:
    const [deleteopen,setDeleteOpen] = React.useState(false);
    const [deletedata,setDeleteData] = React.useState('')


    React.useEffect(() => {
        getCareerdetails()
    }, [page, rowsPerPage])

    const getCareerdetails = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `ApplicationForm/getFormDataall?size=${rowsPerPage}&page=${page}`)
            .then((res) => {
                setRows(res.data.formData)
                setTotal(res.data.totalcount)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }


    React.useEffect(()=>{
        GetPositions()
    },[])


    const GetPositions = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + 'positions/getPositionsforAdmin')
            .then((res) => {
                setDesignation(res.data.getPositions)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }



    const handleClickOpen = (data) => {
        setViewData(data)
        setOpen(true)
    };

    const handleClickClose = () => {
        setViewData('')
        setOpen(false)
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };


    //Add Modal functionality:
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => {setModalOpen(false);setError('')};


    const showPositions = () => setshowPosition(true);
    const hidePositions = () => setshowPosition(false);


    const handleAdd = () => {
        if(inputValue){
            setItems([...items, inputValue]);
            setInputValue('');
        }
       
        
    };


    const handleDelete = (index) => {
       setItems(items.filter((_, i) => i !== index));
       
    };

    const Validation = () => {
        if (items.length == 0) {
            setError({ positionsmsg: 'This field is required' })
            return false
        }
        return true
    }

    const handleSubmit = () => {
        let valid = Validation()
        if (valid) {
            let body = {
                "positions": items
            }
            axios.post(process.env.REACT_APP_BASE_URL + `positions/AddPositions`, body)
                .then((res) => {
                    setItems([])
                    enqueueSnackbar("Positions added successfully", { variant: 'success' })
                    handleClose();
                })
                .catch((error) => {
                    console.log(error, "error in HandleSubmit");
                })
        }

    };


    const downloadPDF = (item) => {
        if (!item) {
            console.error('PDF URL is missing');
            return;
        }

        const baseUrl = process.env.REACT_APP_BASE_URL;
        const pdfUrl = `${baseUrl}${item.replace(/^\/+/, '')}`;


        const newWindow = window.open(pdfUrl, '_blank');
        if (newWindow) {
            newWindow.focus();
        } else {
            console.error('Failed to open new window. Please check popup settings.');
        }
    };


    const handleDeletePosition = (data) => {
        setDeleteData(data)
        setDeleteOpen(true)
        setshowPosition(false)
    }

    const handleDeleteClose = () => {
        setDeleteOpen(false)
        setDeleteData('')
        setshowPosition(true)

    }


    const PositionDelete = (data) => {
        let body = {
            "position":data
        }
        axios.put(process.env.REACT_APP_BASE_URL + 'positions/DeletePosition',body)
        .then((res)=>{
            enqueueSnackbar('Position deleted successfully',{variant:'success'})
            GetPositions()
            setDeleteOpen(false)
            setshowPosition(true)
        })
        .catch((error)=>{
            console.log(error,"error in PositionDelete");
        })
    }





















    return (
        <Box className=''>
            <MiniDrawer type={5} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h6' className='formTitle'>Career Details</Typography>
                </Box>

                <Grid item xs={12} sm={12} md={12} className='d-flex justify-content-end mb-20 mr-10' >
                    {designation && designation?.length > 0 && <Button className='theme-btn ml-5' onClick={showPositions} >Show Positions</Button>}
                    <Button className='theme-btn ml-5' onClick={handleOpen} >Add</Button>
                </Grid>
                <Box className='whiteContainer'>
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>

                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <TableHead className='DT-head'>
                                        <TableRow >
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Profile </strong>  </TableCell>
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Full Name </strong>  </TableCell>
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Email Address</strong>  </TableCell>
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Phone</strong>  </TableCell>
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Positions</strong>  </TableCell>
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Resume</strong>  </TableCell>
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>LinkedIn</strong>  </TableCell>
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Action</strong>  </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            rows && rows?.length > 0 ? rows.map((row, index) => {
                                                let fullPath = row && row.resume;
                                                let filename = fullPath ? fullPath.split('/').pop().replace(/^\d+-/, '') : '';
                                                const adjustedPath = fullPath ? fullPath.replace('public/', '') : '';
                                                return (
                                                    <>
                                                        <TableRow hover role="checkbox">
                                                            <TableCell align="left" className='DT-row'> {<img src={row.portfolio ? process.env.REACT_APP_BASE_URL + row.portfolio : userimage} width={40} style={{ borderRadius: 20 }} height={40}></img>}</TableCell>
                                                            <TableCell align="left" className='DT-row'>{row && row.fullName}</TableCell>
                                                            <TableCell align="left" className='DT-row'>{row && row.emailAddress}</TableCell>
                                                            <TableCell align="left" className='DT-row' >{row && row.phoneNumber}</TableCell>
                                                            <TableCell align="left" className='DT-row' >{row && row.position}</TableCell>
                                                            <TableCell align="left" className='DT-row'><Box className="d-flex align-items-center">{filename}<FileDownloadSharpIcon color='gray' onClick={() => downloadPDF(fullPath)} style={{ cursor: 'pointer', marginLeft: '8px' }} /></Box></TableCell>
                                                            <TableCell align="left" className='DT-row' >{row.linkedInProfile ? row.linkedInProfile : '-'}</TableCell>
                                                            <TableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }} >
                                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Tooltip title="View"><Box onClick={(e) => handleClickOpen(row)}><VisibilityIcon className='icons text-grey' /></Box></Tooltip>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                );
                                            })
                                                :
                                                <TableRow >
                                                    <TableCell align="center" colSpan={6} className='DT-row'><p className='text-center semibold m-0 fz-16'>No data found</p></TableCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            className='DT-pagination'
                        />

                        <BootstrapDialog onClose={handleClickClose} aria-labelledby="customized-dialog-title" open={open} className='custom_modal' maxWidth='sm'>
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Career Details</DialogTitle>
                            <DialogContent dividers>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW120 d-inline-block'>Profile: </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 '>{<img src={viewData.portfolio ? process.env.REACT_APP_BASE_URL + viewData.portfolio : userimage} width={40} height={40}></img>}</Typography>
                                </Box>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW120 d-inline-block'>Name: </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 '>{viewData && viewData.fullName}</Typography>
                                </Box>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW120 d-inline-block'>Email: </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 '>{viewData && viewData.emailAddress ? viewData.emailAddress : '-'}</Typography>
                                </Box>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW120 d-inline-block'>Phone: </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 '>{viewData && viewData.phoneNumber}</Typography>
                                </Box>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW120 d-inline-block'>Position: </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 '>{viewData && viewData.position ? viewData.position : '-'}</Typography>
                                </Box>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW120 d-inline-block'>
                                        Resume:
                                    </Typography>
                                    <Typography component='span' variant='body1' className='fz-14'>
                                        {viewData && viewData.resume ? viewData.resume.split('/').pop().replace(/^\d+-/, '') : '-'}
                                    </Typography>
                                    <FileDownloadSharpIcon
                                        color='gray'
                                        onClick={() => downloadPDF(viewData.resume)}
                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                    />
                                </Box>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW120 d-inline-block'>Cover Letter: </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 '>{" "}{viewData && viewData.coverLetter ? viewData.coverLetter : '-'}</Typography>
                                </Box>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW120 d-inline-block'>LinkedIn: </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 '>{viewData && viewData.linkedInProfile ? viewData.linkedInProfile : '-'}</Typography>
                                </Box>

                                
                            </DialogContent>

                            <DialogActions>
                                <Button className='cancel_btn' onClick={handleClickClose}> Close</Button>
                            </DialogActions>
                        </BootstrapDialog>
                    </Box>
                </Box>
             
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={modalopen}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Add Positions
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <TextField
                            className='textfield'
                            value={inputValue}
                            onChange={(e) => {setInputValue(e.target.value);setError({positionsmsg:''})}}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleAdd} edge="end">
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {error.positionsmsg && <p style={{color:'red',marginTop:10}}>{error.positionsmsg}</p>}
                        <Box display="flex" alignItems="center" className="flex-wrap" marginTop={2}>
                            {items.map((item, index) => (
                                <Box key={index} display="flex" alignItems="center" className="mb-10" marginRight={1}>
                                    <Button variant="contained" className='theme-btn semibold' style={{ margin: '0 5px', borderRadius: 20 }}>
                                        {item}
                                        <IconButton size="small" onClick={() => handleDelete(index)} style={{ marginLeft: '5px', color: 'white', fontWeight: 'bold' }}>
                                            <CloseIcon fontSize="inherit" color='white' />
                                        </IconButton>
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus className='theme-btn' onClick={handleSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </BootstrapDialog>


                <Dialog open={deleteopen} keepMounted onClose={handleDeleteClose}
                            aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                            <DialogTitle>Would you like to delete this Position?</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleDeleteClose} className='cancel_btn'>No</Button>
                                <Button onClick={()=>PositionDelete(deletedata)} className='theme-btn'>Yes</Button>
                            </DialogActions>
                </Dialog>


                <BootstrapDialog
                    onClose={hidePositions}
                    aria-labelledby="customized-dialog-title"
                    open={showPosition}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Positions
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={hidePositions}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>

                        <Box display="flex" alignItems="center" className="flex-wrap" marginTop={2}>
                            {designation && designation.length > 0 ?
                            (designation && designation?.map((item,i)=>{
                                return(
                            <>
                            {item && item?.positions.map((data,i)=>(
                                <Button key={i} variant="contained" className='theme-btn mb-10 semibold' style={{ margin: '0 5px', borderRadius: 20 }}>
                                {data}
                                <IconButton size="small" style={{ marginLeft: '5px', color: 'white', fontWeight: 'bold' }}>
                                    <CloseIcon fontSize="inherit" color='white' onClick={()=>handleDeletePosition(data)} />
                                </IconButton>
                            </Button>
                            ))}
                            
                            </>
                                )
                                
                            })) : (
                                <p style={{marginLeft:200,color:'gray'}}>No positions found</p>
                            )}
                            
                            
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus className='theme-btn' onClick={hidePositions}>
                            Close
                        </Button>
                    </DialogActions>
                </BootstrapDialog>

                
            </Box>
        </Box>
    );
}