import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Button, FormControl, InputAdornment, TextField, Grid } from '@mui/material';
import {SearchOutlined, InfoOutlined, DeleteOutline } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack'
import DialogContent from '@mui/material/DialogContent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


export default function DataTable() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const auth = localStorage.getItem("userData")
    const { enqueueSnackbar } = useSnackbar()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Sector');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [total, setTotal] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [addCaller, setAddCaller] = React.useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [category, setCategory] = React.useState({ categoryname: '', title: '', description: "" })
    const [editId, setEditId] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [deleteid, setDeleteId] = React.useState('');
    const [error, setError] = React.useState({ categoryname: "" })
    const [search, setSearch] = React.useState('');
    let name, value;
    const handlechange = (e) => {
        name = e.target.name;
        value = e.target.value;
        setCategory({ ...category, [name]: value })
    }
    const CloseDialouge = () => {
        setCategory({ categoryname: '', title: '', description: "" })
        setError({ categoryname: "" })
        setAddCaller(false)
    }
    let navigate = useNavigate()
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    React.useEffect(() => {
        getSubCategory()
    }, [page, rowsPerPage, search])


    const getSubCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Subcategory/GetSubCategory?search=${search}&size=${rowsPerPage}&page=${page}&isActive=all`, { headers: headerlocal }).then((res) => {
            setRows(res.data.subcategories)
            setTotal(res.data.totalcount)
        }).catch((error) => {
            console.log(error.response, "error in get cat");
        })
    }

    const handleChangeStatus = async (e, data) => {
        var body = {
            isActive: e.target.checked,

        }
        await axios.put(process.env.REACT_APP_BASE_URL + `Subcategory/EditSubCategoryToggle/${data._id}`, body, { headers: headerlocal }).then((res) => {
            enqueueSnackbar("Status Updated successfully", { variant: 'success' })
            getSubCategory();
        }).catch((err => {
            console.log(err.response, "error while shop enable");
        }))
    }

    const UpdateCategory = async () => {
        var valid = validate()
        if (valid == true) {
            var body = {
                category_name: category.categoryname,
                category_title: category.title,
                description: category.description,

            }
            await axios.put(process.env.REACT_APP_BASE_URL + `category/EditCategory/${editId}`, body, { headers: headerlocal })
                .then((res) => {
                    enqueueSnackbar("Category Updated successfully", { variant: 'success' })
                    setAddCaller(false)
                    setCategory({ categoryname: "", title: "", description: "" });
                    setEditId('');
                    getSubCategory();
                })
                .catch((error) => {
                    console.log(error.response, "error");
                })
        }
    }
    const handleDeleteCategory = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `Subcategory/DeleteSubCategory/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                enqueueSnackbar("Sub Category deleted successfully", { variant: 'success' })
                getSubCategory()
                handleClose()
            }).catch((error) => {
                console.log(error, "error");
            })
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const validate = () => {
        if (!category.categoryname) {
            setError({ categoryname: "Please add category name" })
            return false
        }
        return true
    }
    const handleeditCategory = (e, data) => {
        setAddCaller(true)
        setCategory({ categoryname: data.category_name, title: data.category_title, description: data.description })
        setEditId(data._id)
    }
    const handleClickOpen = (id) => {
        setDeleteId(id)
        setOpen(true);
    };
    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };

    const PassData = (row) => {
        let obj = {
            data: row
        }
        navigate("/editsubcategory", { state: obj })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;



    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(rows);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        var fromvar = [...items]
        var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;

        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        } else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }
        setRows(fromvar);

        var body = {
            index_position: currElIndexNumber
        }


        axios.put(process.env.REACT_APP_BASE_URL + `Subcategory/updateSubCategoryPosition/${final._id}`, body, { headers: headerlocal })
            .then((res) => {
                console.log(res.data, "drag response");
                getSubCategory();
            }).catch((err) =>
                console.log(err.response, "drag & drop")
            )
    }




    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Box component='div' className='DTsearch' style={{ margin: 30 }}>
                    <FormControl variant="standard">
                        <TextField
                            id="filled-size-small"
                            variant="outlined"
                            size="small"
                            value={search}
                            className='textfield search '
                            onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                            placeholder='Search Here...'
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                            }}
                        />
                    </FormControl>
                </Box>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <TableHead className='DT-head'>
                            <TableRow >
                                <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px' }}>Drag row</TableCell>

                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Sub Category Name </strong>  </TableCell>
                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Category Name </strong>  </TableCell>
                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Title</strong>  </TableCell>
                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Action</strong>  </TableCell>
                            </TableRow>
                        </TableHead>

                        <DragDropContext onDragEnd={handleOnDragEnd} >
                            <Droppable droppableId="characters">
                                {(provided) => (

                                    <TableBody className="characters "   {...provided.droppableProps} ref={provided.innerRef}>
                                        {
                                            rows && rows?.length > 0 ? rows.map((row, i) => {

                                                return (
                                                    <Draggable key={row._id} draggableId={row._id} index={i} >
                                                        {(provided) => (
                                                            <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} hover role="checkbox">

                                                                <TableCell className='DT-row' style={{ width: 200 }} align="left"><DragHandleIcon /> </TableCell >


                                                                <TableCell align="left" className='DT-row'>{row && row.subcategory_name}</TableCell>

                                                                <TableCell align="left" className='DT-row'>{row && row.category_id.category_name ? row.category_id.category_name : "-"}</TableCell>
                                                                <TableCell align="left" className='DT-row' >{row.subcategory_title ? row.subcategory_title : "-"}</TableCell>
                                                                {/* <TableCell component="th" scope="row" padding="none" className='DT-row'  >
                                                <FormControlLabel onChange={(e) => handleChangeStatus(e, row)} checked={row.isActive} control={<IOSSwitch sx={{ m: 1 }} />} />
                                            </TableCell> */}
                                                                <TableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }} >
                                                                    <Box style={{ display: 'flex', alignItems: 'center' }}>

                                                                        <Tooltip title="Edit"><Box className='a_tag' onClick={() => PassData(row)}><EditOutlinedIcon className='icons text-grey' /></Box></Tooltip>
                                                                        <Tooltip title="Delete"><Box sx={{ color: "red" }} onClick={(e) => handleClickOpen(row._id)}><DeleteOutline className='icons text-grey' /></Box></Tooltip>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </Draggable>
                                                );
                                            })
                                                :
                                                <TableRow >
                                                    <TableCell align="center" colSpan={6} className='DT-row'><p className='text-center semibold m-0 fz-16'>No data found</p></TableCell>
                                                </TableRow>}


                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 35 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Table>
                </TableContainer>

            </Paper>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className='DT-pagination'
            />
            <BootstrapDialog
                onClose={() => CloseDialouge()}
                aria-labelledby="customized-dialog-title"
                open={addCaller}
                className='addForm-modal adduserAlert_Modal'
            >
                <DialogContent className='deleteConfirmModal' >
                    <InfoOutlined className='confirm_icon' />
                    <Typography component='div' className='text' >{editId ? "Edit category" : "Add Sub category"}</Typography>
                </DialogContent>
                <DialogActions className='deleteModalactbtns'>
                    <Button autoFocus onClick={() => setAddCaller(false)} className='modalbtn cancel'>Cancel</Button>
                </DialogActions>
            </BootstrapDialog>
            <BootstrapDialog
                onClose={() => setAddCaller(false)}
                aria-labelledby="customized-dialog-title"
                open={addCaller}
                className='addForm-modal'
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => CloseDialouge()} className='modal-title'><strong>{editId ? "Edit category" : "Add Sub category"}</strong></BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container columnSpacing={2} rowSpacing={2}>
                        <Grid item xs={12} sm={12} md={4} >
                            <Typography component='div' className='label'>Category Name </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <FormControl variant="standard" fullWidth>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    name='categoryname'
                                    className='textfield'
                                    placeholder='Category Name'
                                    value={category.categoryname}
                                    onChange={(e) => { handlechange(e); setError({ categoryname: '' }) }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                />
                                {error && <p style={{ color: 'red' }} className="fz-13 m-0">{error.categoryname}</p>}

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Typography component='div' className='label'>Title</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <FormControl variant="standard" fullWidth>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    name='title'
                                    className='textfield'
                                    placeholder='Title'
                                    onChange={(e) => handlechange(e)}
                                    value={category.title}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <Typography component='div' className='label'>Description</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <FormControl variant="standard" fullWidth>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    name='description'
                                    className='textfield'
                                    placeholder='Description'
                                    onChange={(e) => handlechange(e)}
                                    value={category.description}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" sx={{ cursor: 'pointer' }} onClick={UpdateCategory} >Update</Button>
                </DialogActions>
            </BootstrapDialog>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'
            >
                <DialogTitle>{"Would you like to delete Sub Category?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={handleDeleteCategory} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}