import * as React from 'react';
import Box from '@mui/material/Box';
import { TableBody, Typography, Grid, Paper, Button, TextField, Select, InputAdornment, FormControl, Tooltip, Card, Input, Divider, CardActionArea, Menu } from '@mui/material';
import { PlaylistAddRounded, InfoOutlined, DataArraySharp, EditOutlined, Close, Add, GridView, ListAlt,SearchOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DOMPurify from 'dompurify';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment'
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import axios, { all } from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack'
import MiniDrawer from '../../../components/drawer'
import Switch, { SwitchProps } from '@mui/material/Switch';
import CardContent from '@mui/material/CardContent';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';

const ITEM_HEIGHT = 15;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function FormPropsTextFields() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);
    const [deleteid, setDeleteId] = React.useState('');
    const [openview, setOpenview] = React.useState(false);
    const [alldata, setAlldata] = React.useState('');
    const [search, setSearch] = React.useState("");


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    const handleClose_menu = () => {
        setAnchorEl(null);
    };


    React.useEffect(() => {
        getBlog()
    }, [rowsPerPage, page,search])

    const getBlog = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `blog/getBlogs?size=${rowsPerPage}&page=${page}&search=${search}`, { headers: headerlocal })
            .then((res) => {
                setNewdata(res.data.getBlogs)
                setTotal(res.data.totalcount)
            }).catch((error) => {
                console.log(error, "error in service get");
            })
    }

    const handleDeleteBlog = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `blog/DeleteBlog/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                enqueueSnackbar("Blog deleted successfully", { variant: 'success' })
                getBlog()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleNavigate = () => {
        navigate('/addblogs')
    }



    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };


    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate("/editblogs", { state: val })
    }

    const handleView = () => {
        setAnchorEl(null);
        setOpenview(true)

    }

    var sanitizedContent = DOMPurify.sanitize(alldata.Description, { ALLOWED_TAGS: ['img'] })


    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }

    const handleChangeStatus = async (e, data) => {
        var body = {
            isActive: e.target.checked,
        }
        await axios.put(process.env.REACT_APP_BASE_URL + `Blog/EditBlogStatus/${data._id}`, body, { headers: headerlocal }).then((res) => {
            // enqueueSnackbar("Status Updated successfully", { variant: 'success' })
            getBlog();
        }).catch((err => {
            console.log("error while shop enable");
        }))
    }


    return (
        <>
            <Box className=''>
                <MiniDrawer type={4} />
                <Box component="section" className="contentWraper">
                    <Box className='headingBox'>
                        {/* <Typography component='h4' variant='h4' className='Submenu-page-heading'>Team Members</Typography> */}
                        <Typography component='h6' className='formTitle'>Blogs</Typography>

                        <Button variant="contained" className='theme-btn' onClick={(e) => handleNavigate()}> <PlaylistAddRounded className='fz-18 mr-5' />Add Blogs</Button>

                    </Box>
                    <Grid container rowSpacing={2} columnSpacing={2} style={{ marginBottom: 10 }} className='align-items-end'>
                         
                         <Grid item xs={12} sm={6} md={4} lg={3}>
                             <FormControl variant="standard" fullWidth>
                                 <FormControl fullWidth >
                                     <TextField
                                         id="filled-size-small"
                                         variant="outlined"
                                         size="small"
                                         value={search}
                                         className='textfield search '
                                          onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                                         placeholder='Search Here...'
                                         InputProps={{
                                             endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                                         }}
                                     />
                                 </FormControl>
                             </FormControl>
                         </Grid>
                     
                     </Grid>


                    <Box className='whiteContainer p-20'>
                        <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList" >
                            {newdata && newdata.length > 0 ? newdata.map((data, index) => (
                            

                                <Grid key={index} item xs={12} sm={6} md={4} lg={3} sx={{ marginTop: 2 }}>
                                    <Card className="cardGrid" >
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={menuopen ? 'long-menu' : undefined}
                                            aria-expanded={menuopen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, data)}
                                            className='vertical_menu'
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={menuopen}
                                            onClose={handleClose_menu}
                                            PaperProps={{
                                                style: {
                                                    width: '15ch',
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

                                        >
                                            <MenuItem className='menuitems' onClick={() => handleView()}><VisibilityIcon className='fz-16 mr-5' />View</MenuItem>
                                            <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>
                                            <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>
                                            {/* <MenuItem className='menuitems' onClick={() => handleView()}><VisibilityIcon className='fz-16 mr-5' />View</MenuItem>

                                            <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-18' style={{ margin: 5 }} />Edit</MenuItem>
                                            <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}><DeleteIcon className='fz-18' style={{ margin: 5 }} />Delete</MenuItem> */}
                                        </Menu>

                                        <Swiper
                                            modules={[Navigation, Pagination]}
                                            className="mySwiper "
                                            slidesPerView={1}
                                            pagination={{
                                                clickable: true,
                                            }}
                                        >
                                            {data.Images.map((tdata, i) => {
                                                return (
                                                    <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} />
                                                    </SwiperSlide>
                                                )
                                            })}
                                        </Swiper>


                                        {/* <div className="carosuel">
                                            <Carousel autoPlay={false} dynamicHeight={false} interval={3000} showStatus={false}
                                                showThumbs={false} showIndicators={true}  >

                                                {data.Images.map((tdata, i) => {
                                                    return (
                                                        <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} />
                                                    )
                                                })} </Carousel>
                                        </div> */}


                                        <CardContent>
                                            <Box className='mb-10 d-flex align-items-start justify-content-between'>
                                                <Box className='maxW50'>
                                                    <Typography noWrap className='semibold title' variant="h3" component="h3">{data.Blog_Name}</Typography>
                                                    <Typography className='p_tag' component='div'>{moment(data.Date).format('MMM Do YYYY')}</Typography>
                                                </Box>
                                                <Box className='text-right '>
                                                    <Typography component='div' variant='body1' className='fz-14 semibold'>Enable/Disable:  </Typography>
                                                    <FormControlLabel className='m-0' control={<IOSSwitch className='m-0' checked={data.isActive} onChange={(e) => handleChangeStatus(e, data)} sx={{ m: 1 }} />} />
                                                </Box>
                                            </Box>

                                        </CardContent>
                                    </Card>
                                </Grid>
                            )) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                <p className='text-center semibold'>No data found</p></Grid>}
                        </Grid>
                        <Dialog
                            open={open}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            className='custom_modal'
                        >
                            <DialogTitle>Would you like to delete Blog?</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleClose} className='cancel_btn'>No</Button>
                                <Button onClick={handleDeleteBlog} className='theme-btn'>Yes</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                    <TablePagination
                        // rowsPerPageOptions={[5, 10, 25]}
                        rowsPerPageOptions={[8,12,16,20,24]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='DT-pagination'
                    />
                </Box>

            </Box>


            <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className='custom_modal'
                maxWidth='md'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Blog Details
        </DialogTitle>
                {/* <IconButton
                    aria-label="close"
                    onClick={handleClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton> */}
                <DialogContent dividers>
                    <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Swiper
                                modules={[Navigation, Pagination]}
                                className="mySwiper "
                                slidesPerView={1}
                                pagination={{
                                    clickable: true,
                                }}
                            >
                                {alldata && alldata.Images?.length > 0 && alldata.Images.map((tdata, i) => {
                                    return (
                                        <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} />
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>

                            <Box className='mb-10'>
                                <Typography component='div' variant='body1' className='fz-16 semibold'>Blog </Typography>
                                <Typography component='div' variant='body1' className='fz-16 '>{alldata.Blog_Name}</Typography>
                            </Box>

                            <Box className='mb-10'>
                                <Typography component='div' variant='body1' className='fz-16 semibold'>Category Name </Typography>
                                <Typography component='div' variant='body1' className='fz-16 '>{alldata && alldata.Category.category_name}</Typography>
                            </Box>
                            <Box className=''>
                                <Typography component='div' variant='body1' className='fz-16 semibold'>Created Date </Typography>
                                <Typography component='div' variant='body1' className='fz-16 '>{alldata && moment(alldata.Date).format('MMM Do YYYY')}</Typography>
                            </Box>

                        </Grid>
                    </Grid>


                    <Typography component='div' variant='body1' className='fz-14 semibold'>Description: </Typography>
                    {sanitizedContent ? <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                        : <Typography className='p18_tag' >There is no description</Typography>}

                </DialogContent>
                <DialogActions>
                    <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>


        </>
    );
}


// import React, { useState } from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// const App = () => {
//     const [editorData, setEditorData] = useState("");
// // console.log(editorData,"editorData");
//     const handleReady = (editor) => {
//         // You can store the "editor" and use it when needed.
//         // console.log('Editor is ready to use!', editor);
//     };

//     const handleChange = (event, editor) => {
//         const data = editor.getData();
//         setEditorData(editor.getData());
//         const parser = new DOMParser();
//         const parsedHtml = parser.parseFromString(data, 'text/html');
//         const plainText = parsedHtml.body.textContent || "";
//         console.log(plainText,"Plain text content:");
//     };

//     const handleBlur = (event, editor) => {
//         // console.log('Blur.', editor);
//     };

//     const handleFocus = (event, editor) => {
//         // console.log('Focus.', editor);
//     };

//     return (
//         <div className="App">
//             <h2>Using CKEditor&nbsp;5 build in React</h2>
//             <CKEditor
//                 editor={ClassicEditor}
//                 data={editorData}
//                 onReady={handleReady}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//                 onFocus={handleFocus}
//             />
//         </div>
//     );
// };

// export default App;
