import { BusinessOutlined, Lock, Person } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, IconButton, OutlinedInput } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useCookies } from 'react-cookie';
import { VisibilityOff, Visibility } from '@mui/icons-material';

export default function Login() {
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [error, setError] = React.useState({ email: "", password: "" })
    const [signin, setSignin] = React.useState({ email: "", password: "" })
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
	const [truebutton, setTruebutton] = React.useState(false)

    let name, value
    const handleChange = (e) => {
        name = e.target.name;
        value = e.target.value;
        setSignin({ ...signin, [name]: value })
    }


    const Validator = () => {
        if (!signin.email && !signin.password) {
            setError({ email: 'This field is required.', password: 'This field is required.' })
            return false
        }
        if (!signin.email) {
            setError({ email: 'This field is required.' })
            return false
        }
        if (!signin.password) {
            setError({ password: 'This field is required.' })
            return false
        }
        return true
    }

    // const Validator = () => {
    //     if (!signin.email && !signin.password) {
    //         setError({ email: "Email cannot be empty", password: "Password cannot be empty" })
    //         return false
    //     }
    //     if (!signin.email) {
    //         setError({ email: "Email cannot be empty" })
    //         return false
    //     }
    //     if (!signin.password) {
    //         setError({ password: "Password cannot be empty" })
    //         return false
    //     }
    //     return true
    // }


    const [values, setValues] = React.useState({
        showPassword: false,
        showRePassword: false
    });

  
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            LoginSubmit(e)
        }
    }


    const LoginSubmit = async () => {
        var signinvalidation = Validator();
        if (signinvalidation == true) {
            setTruebutton(true)
            var body = {
                "email": signin.email,
                "password": signin.password,
            }
            await axios.post(process.env.REACT_APP_BASE_URL + `admin/AdminLogin`, body).then((res) => {
                setTruebutton(false)
                localStorage.setItem("userData", JSON.stringify(res.data.admin))
                localStorage.setItem("token", res.data.token)
                setCookie('value', res.data.token);
                navigate('/service')
                enqueueSnackbar("Login successfully", { variant: 'success' })
            }).catch((error) => {
                setTruebutton(false)

                enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                console.log(error.message, "error");
            })
        }
    }

    return (
        <Box className='login'>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='login_img'>
                    </Box>
                    <Box className='img_container'>
                        <img src={require('./../../assets/images/logo.png')} className='img_1'></img>
                        <img src={require('./../../assets/images/img_2.png')} className='img_2'></img>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    
                    <Box className='Login_content'>
                        <Grid style={{textAlign:'center',marginBottom:50}}>
                        <img src={require('./../../assets/images/logo.png')} className='img_2'></img>

                        </Grid>
                    

                        {/* <Typography component='h4' variant='h4'>Admin Login</Typography> */}

                        <Box className='form' >
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Email</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield'
                                    placeholder='Enter your email'
                                    name="email"
                                    value={signin.email}
                                    onChange={(e) => { handleChange(e); setError({ email: "" }) }}
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start"><Person className='start_icon' /></InputAdornment>,
                                    // }}
                                // helperText={<div style={{ fontSize: 14, color: "red", marginLeft: -8, marginTop: 5 }}>{error.email}</div>}
                                />
                                 {/* <p className='error-field'>{error.password}</p> */}
                                {error.email && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.email}</p>}
                            </FormControl>

                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Password</Typography>

                                <OutlinedInput
                                    variant="outlined"
                                    size="small"
                                    name="password"
                                    value={signin.password}
                                    onChange={(e) => { handleChange(e); setError({ password: "" }) }}
                                    className='textfield '
                                    placeholder='Enter Password'
                                    type={values.showPassword ? 'text' : 'password'}
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>,
                                    // }}
                                    InputAdornment={
                                        <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>
                                    }
                                    onKeyDown={_handleKeyDown}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>

                                    }
                                />
                                    {error.password && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.password}</p>}
                   
                            </FormControl>

                            {/* <FormControl variant="standard">
                                <Typography component='div' className='label'>Password</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield'
                                    placeholder='Enter Password'
                                    name="password"
                                    value={signin.password}
                                    onChange={(e) => { handleChange(e); setError({ password: "" }) }}
                                    type='password'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>,
                                    }}
                                    onKeyDown={_handleKeyDown}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>

                                    }



                                // helperText={<div style={{ fontSize: 14, color: "red", marginLeft: -8, marginTop: 5 }}>{error.password}</div>}
                                />{error.password && <p style={{ color: 'red' }} >{error.password}</p>}
                            </FormControl> */}
                        </Box>
                        {truebutton == false ? 
                        <Button className='loginbtn' onClick={() => LoginSubmit()}>LOGIN</Button>
                        :
                        <Button className='loginbtn'>Please wait...</Button>}
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}
