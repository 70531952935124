import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from '../pages/Authentication/Login'
import Category from '../pages/Home/Category'
import AddCategory from '../pages/Home/Category/addCategory'
import Editsubcat from '../pages/Home/subcategory/editsub'
import Subcategory from '../pages/Home/subcategory'
import Addsubcat from '../pages/Home/subcategory/addsub'
import Service from '../pages/Home/Service'
import Service_Steppyform from '../pages/Home/Service/steppyform'
import Contatus from '../pages/Home/Settings/contact/index'
import Enquirey from '../pages/Home/Settings/Enquiry/index'
import Editcategory from '../pages/Home/Category/edit'
import Brochure from '../pages/Home/Settings/Brochure/index'
import Editservice from '../pages/Home/Service/editservice'
import Team from '../pages/Home/Teammembers/index'
import AddTeam from '../pages/Home/Teammembers/addteam'
import EditTeam from '../pages/Home/Teammembers/editteam'
import Blogs from '../pages/Home/Blogs/index'
import Addblogs from '../pages/Home/Blogs/addblog'
import Editblogs from '../pages/Home/Blogs/editblog'
import Ourpartners from '../pages/Home/ourpartners/index'
import Addpartners from '../pages/Home/ourpartners/add'
import Editpartners from '../pages/Home/ourpartners/edit'
import PrivateRoute from '../common/privateRoute'

import Project from '../pages/Home/Project/index'
import Addproject from '../pages/Home/Project/addproject'
import Editproject from '../pages/Home/Project/editproject'

import Gallery from '../pages/Home/Gallery/index'
import AddGallery from '../pages/Home/Gallery/addgallery'
import EditGallery from '../pages/Home/Gallery/editgallery'

import Career from '../pages/Home/Settings/Career'

export default function Routers() {
    return (
        <Router>
            <Routes>
                <Route exact path='/' element={<Login /> }></Route>
                <Route exact path='/addcategory' element={<PrivateRoute> <AddCategory /></PrivateRoute>}></Route>
                <Route exact path='/editcategory' element={<PrivateRoute> <Editcategory /></PrivateRoute>}></Route>
                <Route exact path='/category' element={<PrivateRoute> <Category /></PrivateRoute>}></Route>
                {/* <Route exact path='/subcategory' element={<PrivateRoute> <Subcategory /></PrivateRoute>}></Route>
                <Route exact path='/addsubcategory' element={<PrivateRoute> <Addsubcat /></PrivateRoute>}></Route>
                <Route exact path='/editsubcategory' element={<PrivateRoute> <Editsubcat /></PrivateRoute>}></Route> */}
                <Route exact path='/service' element={<PrivateRoute> <Service /></PrivateRoute>}></Route>
                <Route exact path='/servicesteps' element={<PrivateRoute> <Service_Steppyform /></PrivateRoute>}></Route> 
                <Route exact path='/contatus' element={<PrivateRoute> <Contatus /></PrivateRoute>}></Route>
                <Route exact path='/enquiry' element={<PrivateRoute> <Enquirey /></PrivateRoute>}></Route>
                <Route exact path='/brochure' element={<PrivateRoute> <Brochure /></PrivateRoute>}></Route>
                <Route exact path='/editservice' element={<PrivateRoute> <Editservice /></PrivateRoute>}></Route>
              
                {/* <Route exact path='/team' element={<PrivateRoute> <Team/></PrivateRoute>}></Route>
                <Route exact path='/addteam' element={<PrivateRoute> <AddTeam /></PrivateRoute>}></Route>     
                <Route exact path='/editteam' element={<PrivateRoute> <EditTeam /></PrivateRoute>}></Route>    */}

                {/* <Route exact path='/blogs' element={<PrivateRoute> <Blogs /></PrivateRoute>}></Route> 
                <Route exact path='/addblogs' element={<PrivateRoute> <Addblogs /></PrivateRoute>}></Route> 
                <Route exact path='/editblogs' element={<PrivateRoute> <Editblogs /></PrivateRoute>}></Route> */}

                 

                <Route exact path='/ourpartners' element={<PrivateRoute> <Ourpartners /></PrivateRoute>}></Route>
                <Route exact path='/addpartners' element={<PrivateRoute> <Addpartners /></PrivateRoute>}></Route>
                <Route exact path='/editpartners' element={<PrivateRoute> <Editpartners /></PrivateRoute>}></Route>

                <Route exact path='/ourwork' element={<PrivateRoute> <Project /></PrivateRoute>}></Route>
                <Route exact path='/addourwork' element={<PrivateRoute> <Addproject /></PrivateRoute>}></Route>
                <Route exact path='/editourwork' element={<PrivateRoute> <Editproject /></PrivateRoute>}></Route>
                
                <Route exact path='/gallery' element={<PrivateRoute> <Gallery /></PrivateRoute>}></Route>
                <Route exact path='/addgallery' element={<PrivateRoute> <AddGallery /></PrivateRoute>}></Route>
                <Route exact path='/editgallery' element={<PrivateRoute> <EditGallery /></PrivateRoute>}></Route>

                <Route exact path='/career' element={<PrivateRoute> <Career /></PrivateRoute>}></Route>

                       
            </Routes>
        </Router>
    )
}