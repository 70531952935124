import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './Datatable'

export default function Category() {
    let navigate = useNavigate()

    const AddCategory = () => {
        navigate('/addcategory');
    }
    return (
        <Box className=''>
            <MiniDrawer type={1}/>
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    {/* <Typography component='h4' variant='h4' className='Submenu-page-heading'>Category</Typography> */}
                    <Typography component='h6' className='formTitle'>Category</Typography>
                    <Button variant="contained" className='theme-btn' onClick={() => AddCategory()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Category</Button>
                </Box>
                <Box className='whiteContainer'>
                    <DataTable />
                </Box>
            </Box>
        </Box>
    )
}
