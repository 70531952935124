import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Grid, Input } from '@mui/material';
import MiniDrawer from '../../../../components/drawer';
import 'react-phone-number-input/style.css'
import axios from 'axios';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useSnackbar } from 'notistack'

export default function Brochure() {
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }

    const [selectedFile, setSelectedFile] = React.useState('');
    const [button, setButton] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar()
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };


    React.useEffect(() => {
        getBrochure()
    }, [])

    const getBrochure = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `pdf/getPdf`, { headers: headerformdata })
            .then((response) => {
                console.log(response, "response");
                setSelectedFile(response.data.pdf ? response.data.pdf.pdfFile : "")
            })
            .catch((err) => {
                console.log(err, "error");
            })
    }

    const handleSubmit = async () => {
        if (selectedFile) {
            setButton(true)
            const formData = new FormData();
            formData.append('pdfFile', selectedFile);
            await axios.post(process.env.REACT_APP_BASE_URL + `pdf/pdfUpload`, formData, { headers: headerformdata })
                .then(response => {
                    setButton(false)
                    // setSelectedFile(null)
                    enqueueSnackbar('File/pdf Uploaded successfully', { variant: 'success' });
                }).catch(error => {
                    setButton(false)

                    console.log(error.response, "error");
                    enqueueSnackbar('Error during upload', { variant: 'error' })
                });
        } else {
            enqueueSnackbar('No file selected for upload.', { variant: 'error' })

            console.warn('No file/pdf selected for upload please select any file/pdf');
        }
    };
    return (
        <Box className=''>
            <MiniDrawer type={5} />
            <Box component="section" className="contentWraper ">
                <Box sx={{ width: '100%' }} >
                    <Box className='headingBox mb-2'>
                        <Typography component='h6' className='formTitle'>Brochure</Typography>
                        {/* <Typography component='h4' variant='h4' className='Submenu-page-heading'>Brochure</Typography> */}
                    </Box>
                    <Box className='whiteContainer p-20'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>


                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                    <label className='form-labels m-0 mb-0' for="my-input" >{selectedFile == null ? 'Upload PDF' : 'Current PDF'} <Typography component='span' className='star'>*</Typography></label>



                                    <Box className="mb-10 d-flex justify-content-end  align-items-end">
                                        {selectedFile && <Typography component='h4' variant='h4' className='fz-14 '>{selectedFile.path ? selectedFile.path : selectedFile.name}</Typography>}
                                    </Box>


                                </Box>



                                {button == false ?
                                    <Box className='justify-content-end my-4 d-flex align-items-center brochure_btns'>
                                        <Button className="theme-btn mr-10">
                                            <label htmlFor="contained-button-file1" className="m-0 ">
                                                <Box className='' >
                                                    <Input
                                                        type={"file"} inputProps={{ accept: "application/pdf" }}
                                                        style={{ display: "none", }}
                                                        onChange={handleFileChange}
                                                        id="contained-button-file1"

                                                    />
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Change Doc</Box>
                                                </Box>

                                            </label>
                                        </Button>
                                        <Button onClick={handleSubmit} variant="contained" className='theme-btn ml-10'>Update</Button>
                                    </Box>
                                    :
                                    <Box className='text-right my-4'>
                                        <Button variant="contained" className='theme-btnnewwait'>Please wait...</Button>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
