import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, ClickAwayListener, FormControl, Grow, InputAdornment, MenuItem, MenuList, Popper, TextField, Grid,useMediaQuery  } from '@mui/material';
import { ArrowDropDown, DeleteOutlined, FilterAltRounded, PauseCircleOutlined, SearchOutlined, InfoOutlined, DeleteOutline } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack'
import DialogContent from '@mui/material/DialogContent';
import CancelIcon from '@mui/icons-material/Cancel';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';

export default function DataTable() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const auth = localStorage.getItem("userData")
    const { enqueueSnackbar } = useSnackbar()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Sector');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [total, setTotal] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [category, setCategory] = React.useState({ categoryname: '', title: '', description: "" })
    const [editId, setEditId] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [opennew, setopennew] = React.useState(false);


    const [deleteid, setDeleteId] = React.useState('');
    const [error, setError] = React.useState({ categoryname: "" })
    const [search, setSearch] = React.useState('');


    let navigate = useNavigate()

    const handleClosenew = () => {
        setopennew(false)
    }

    const handleopennew = () => {
        setopennew(true)
    }


    React.useEffect(() => {
        getCategory()
    }, [page, rowsPerPage, search])

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `category/GetCategory?size=${rowsPerPage}&page=${page}&search=${search}&isActive=all`, { headers: headerlocal }).then((res) => {
            setRows(res.data.category)
            setTotal(res.data.totalcount)
        }).catch((error) => {
            console.log(error.response, "error");
        })
    }

    const handleDeleteCategory = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `category/DeleteCategory/${deleteid}`, {}, { headers: headerlocal },)
            .then((res) => {
                enqueueSnackbar("Category deleted successfully", { variant: 'success' })
                getCategory()
                handleClose()
            })
            .catch((error) => {
                console.log(error.response, "error");
                if (error.response.data.error == 'Cannot delete category with associated services' || 'Cannot delete category with associated blogs' || 'Cannot delete category with associated subcategories') {
                    handleClose()
                    handleopennew()
                }
            })

    }

    const handleClickOpen = (id) => {
        setDeleteId(id)
        setOpen(true);
    };
    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };









    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const PassData = (row) => {
        let obj = {
            data: row
        }
        navigate("/editcategory", { state: obj })
    }





    const handleChangeCheck = async (e, data) => {
        var body = {
            setCategory: e.target.checked,
        }
        await axios.put(process.env.REACT_APP_BASE_URL + `category/EditsetCategory/${data._id}`, body, { headers: headerlocal })
            .then((res) => {
                getCategory();
            }).catch((err => {
                enqueueSnackbar(err.response.data.error, { variant: 'error' })
                console.log(err.response.data.error, "error while shop enable");
            }))
    }


    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(rows);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        var fromvar = [...items]
        var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;

        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        } else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }
        setRows(fromvar);

        var body = {
            index_position: currElIndexNumber
        }


        axios.put(process.env.REACT_APP_BASE_URL + `category/updateCategoryPosition/${final._id}`, body, { headers: headerlocal })
            .then((res) => {
                console.log(res.data, "drag response");
                getCategory();
            }).catch((err) =>
                console.log(err.response, "drag & drop")
            )
    }




    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Box component='div' className='DTsearch' style={{ margin: 30 }}>
                    <FormControl variant="standard">
                        {/* <Typography component='div' className='label mr-10' >Search :</Typography> */}
                        <TextField
                            id="filled-size-small"
                            variant="outlined"
                            size="small"
                            value={search}
                            className='textfield search '
                            onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                            placeholder='Search Here...'
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                            }}
                        />
                    </FormControl>
                </Box>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                        <TableHead className='DT-head'>
                            <TableRow >
                            <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px' }}>Drag row</TableCell>
                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 , paddingLeft:45}} align="left" > <strong>Image </strong>  </TableCell>
                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong> Name </strong>  </TableCell>
                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Title</strong>  </TableCell>
                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="center" > <strong>Set as Primary</strong>  </TableCell>
                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Action</strong>  </TableCell>
                            </TableRow>
                        </TableHead>

                        <DragDropContext onDragEnd={handleOnDragEnd} >
                                        <Droppable droppableId="characters">
                                            {(provided) => (
                        <TableBody className="characters "   {...provided.droppableProps} ref={provided.innerRef}>






                            {rows && rows?.length > 0 ? rows.map((row, i) => {

                                return (

                                    <Draggable key={row._id} draggableId={row._id} index={i} >
                                    {(provided) => (

                                    <TableRow  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} hover role="checkbox">
                                       <TableCell className='DT-row' style={{ width: 200 }} align="left"><DragHandleIcon /> </TableCell >

                                        <TableCell align="left" className='DT-row'><img className="cat_image" src={process.env.REACT_APP_BASE_URL + `${row.image}`} alt='' height={50} width={50} /></TableCell>
                                        <TableCell align="left" className='DT-row'>{row.category_name}</TableCell>
                                        <TableCell align="left" className='DT-row'>{row && row.category_title}</TableCell>
                                        <TableCell align="center" component="th" scope="row" padding="none" className='DT-row'  >
                                            <Checkbox onChange={(e) => handleChangeCheck(e, row)} checked={row.setCategory} />
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }} >
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip title="Edit"><Box className='a_tag' onClick={() => PassData(row)}><EditOutlinedIcon className='icons text-grey' /></Box></Tooltip>
                                                <Tooltip title="Delete"><Box onClick={(e) => handleClickOpen(row._id)}><DeleteOutline className='icons text-grey' /></Box></Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    )}
                                  </Draggable>
                                );
                            }) :
                                <TableRow >
                                    <TableCell align="center" colSpan={6} className='DT-row'><p className='text-center semibold m-0 fz-16'>No data found</p></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                           )}
                           </Droppable>
                       </DragDropContext>
                    </Table>
                </TableContainer>
            </Paper>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className='DT-pagination'
            />
            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"Would you like to delete Category?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={handleDeleteCategory} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>

  
             <Dialog open={opennew} keepMounted onClose={handleClosenew} aria-describedby="alert-dialog-slide-description" className='custom_modal alert_modal'>
            <DialogTitle>{"Error"}</DialogTitle>

                 <DialogContent className=''>
                     <Box className='d-flex align-items-center border p-10 '>
                    <CancelIcon className='mr-10' style={{ color: '#f5572c', fontSize: 50 }} />
                    <Box className='fz-16 text-black'>This category has services you cannot delete. If you want to delete this category, please update the service.</Box>
                    </Box>
                 </DialogContent>
                <DialogActions className='' >
                    <Button onClick={handleClosenew} className='theme-btn'>OK</Button>
                </DialogActions>
            </Dialog> 

        </Box>
    );
}
