import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { Button, ClickAwayListener, FormControl, Grow, InputAdornment, MenuItem, MenuList, Popper, TextField, Grid, Card, Divider } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack'
import DialogContent from '@mui/material/DialogContent';
import MiniDrawer from '../../../../components/drawer'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ArrowRightAlt } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

export default function DataTable() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const auth = localStorage.getItem("userData")
    const { enqueueSnackbar } = useSnackbar()
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [total, setTotal] = React.useState(0);
    const [rows, setRows] = React.useState([]);

    const [open, setOpen] = React.useState(false);
    const [viewData, setViewData] = React.useState('');

    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    let navigate = useNavigate()


    React.useEffect(() => {
        getEnquirey()
    }, [page, rowsPerPage])

    const getEnquirey = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `enquiry/getEnquiries?size=${rowsPerPage}&page=${page}`, { headers: headerlocal })
            .then((res) => {
                setRows(res.data.enquiries)
                setTotal(res.data.totalcount)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }



    const handleClickOpen = (data) => {
        setViewData(data)
        setOpen(true)
    };

    const handleClickClose = () => {
        setViewData('')
        setOpen(false)
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };






    return (
        <Box className=''>
            <MiniDrawer type={5} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h6' className='formTitle'>Enquiry Details</Typography>
                </Box>
                <Box className='whiteContainer'>
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>

                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <TableHead className='DT-head'>
                                        <TableRow >
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Name </strong>  </TableCell>
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Company</strong>  </TableCell>
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Email</strong>  </TableCell>
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Phone</strong>  </TableCell>
                                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Action</strong>  </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            rows && rows?.length > 0 ? rows.map((row, index) => {
                                                return (
                                                    <>
                                                        <TableRow hover role="checkbox">
                                                            <TableCell align="left" className='DT-row'>{row && row.first_name}{" "}{row && row.last_name}</TableCell>
                                                            <TableCell align="left" className='DT-row'>{row && row.company ? row.company : "-"}</TableCell>
                                                            <TableCell align="left" className='DT-row' >{row && row.email}</TableCell>
                                                            <TableCell align="left" className='DT-row' >{row && row.phone_number ? row.phone_number : '-'}</TableCell>
                                                            <TableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }} >
                                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Tooltip title="View"><Box onClick={(e) => handleClickOpen(row)}><VisibilityIcon className='icons text-grey' /></Box></Tooltip>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                );
                                            })
                                                :
                                                <TableRow >
                                                    <TableCell align="center" colSpan={6} className='DT-row'><p className='text-center semibold m-0 fz-16'>No data found</p></TableCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                className='DT-pagination'
                            />

                        <BootstrapDialog onClose={handleClickClose} aria-labelledby="customized-dialog-title" open={open} className='custom_modal' maxWidth='sm'>
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Enquiry Details</DialogTitle>
                            <DialogContent dividers>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW80 d-inline-block'>Name: </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 '>{viewData && viewData.first_name}{" "}{viewData && viewData.last_name ? viewData.last_name : ''}</Typography>
                                </Box>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW80 d-inline-block'>Company: </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 '>{viewData && viewData.company ? viewData.company : '-'}</Typography>
                                </Box>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW80 d-inline-block'>Email: </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 '>{viewData && viewData.email}</Typography>
                                </Box>
                                <Box className='mb-5'>
                                    <Typography component='span' variant='body1' className='fz-14 semibold minW80 d-inline-block'>Phone: </Typography>
                                    <Typography component='span' variant='body1' className='fz-14 '>{viewData && viewData.phone_number ? viewData.phone_number : '-'}</Typography>
                                </Box>

                                {viewData && viewData.blog == false ?
                                    <>
                                        <Box>
                                            <Typography component='h6' variant='h6' className='semibold mt-30 mb-10 fz-18'>Interested Services</Typography>
                                            {viewData.advisory_consulting_services == true ? <Typography component='p' variant='body1' className='fz-14 semibold'><ArrowRightAlt className='fz-16 primary_text mr-10' /> Advisory & Consulting Services</Typography> : ""}
                                            {viewData.branding == true ? <Typography component='p' variant='body1' className='fz-14 semibold'><ArrowRightAlt className='fz-16 primary_text mr-10' /> Branding</Typography> : ""}
                                            {viewData.campaigns_360 == true ? <Typography component='p' variant='body1' className='fz-14 semibold'><ArrowRightAlt className='fz-16 primary_text mr-10' />  360 Campaigns</Typography> : ""}
                                            {viewData.content_creation == true ? <Typography component='p' variant='body1' className='fz-14 semibold'><ArrowRightAlt className='fz-16 primary_text mr-10' />  Content Creation</Typography> : ""}
                                            {viewData.data_mining == true ? <Typography component='p' variant='body1' className='fz-14 semibold'><ArrowRightAlt className='fz-16 primary_text mr-10' />  Data Mining</Typography> : ""}
                                            {viewData.digital_strategy_media_buying == true ? <Typography component='p' variant='body1' className='fz-14 semibold'><ArrowRightAlt className='fz-16 primary_text mr-10' />  Digital Strategy & Media Buying</Typography> : ""}
                                            {viewData.lead_generation == true ? <Typography component='p' variant='body1' className='fz-14 semibold'><ArrowRightAlt className='fz-16 primary_text mr-10' />  Lead Generation</Typography> : ""}
                                            {viewData.research_development == true ? <Typography component='p' variant='body1' className='fz-14 semibold'><ArrowRightAlt className='fz-16 primary_text mr-10' />  Research & Development</Typography> : ""}
                                            {viewData.social_media_management == true ? <Typography component='p' variant='body1' className='fz-14 semibold'><ArrowRightAlt className='fz-16 primary_text mr-10' />  Social Media Management</Typography> : ""}
                                            {viewData.websites_applications == true ? <Typography component='p' variant='body1' className='fz-14 semibold'><ArrowRightAlt className='fz-16 primary_text mr-10' />  Websites & Applications</Typography> : ""}

                                            {viewData.advisory_consulting_services == false && viewData.branding == false &&
                                            viewData.campaigns_360 == false && viewData.content_creation == false &&
                                            viewData.data_mining == false && viewData.digital_strategy_media_buying == false &&
                                            viewData.lead_generation == false && viewData.research_development == false &&
                                            viewData.social_media_management == false && viewData.websites_applications == false ?
                                            <Typography component='p' variant='body1' className='fz-14 semibold'>No data found</Typography>:""}

                                        </Box>
                                    </>
                                    :
                                    <>
                                        <Typography component='h6' variant='h6' className='semibold mt-30 mb-10 fz-18'> Blog Comment</Typography>
                                        <Typography component='p' variant='body1' className='fz-14 semibold'>{viewData && viewData.comments ? viewData.comments:'No comments'}</Typography>
                                    </>
                                }
                            </DialogContent>

                            <DialogActions>
                                <Button className='cancel_btn' onClick={handleClickClose}> Close</Button>
                            </DialogActions>
                        </BootstrapDialog>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}