import React, { useRef } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import MiniDrawer from '../../../components/drawer';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Card, Input, CardContent, Dialog, Slide } from '@mui/material'
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#784af4',
	}),
	'& .QontoStepIcon-completedIcon': {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
	'& .QontoStepIcon-circle': {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;
	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};



const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundColor:
			'#E80A89',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	}),
	...(ownerState.completed && {
		backgroundColor:
			'#E80A89',
	}),
}));

function ColorlibStepIcon(props) {
	const { active, completed, className } = props;

	const icons = {
		1: <ListAlt />,
		2: <CompareIcon />,
		3: <Dvr />,
	};
	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};


export default function Addpartners() {
	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}
	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [truebutton, setTruebutton] = React.useState(false)
	const [imageError, setImageError] = React.useState('')
	const [state1, setState1] = React.useState([])
	const [state2, setState2] = React.useState([])

	const multipleImgChange = (e, index) => {
		setImageError('')
		var fileObj = [];
		var fileArray = [];
		var filepath = [];
		fileObj.push(e.target.files)
		for (let i = 0; i <= fileObj[0].length; i++) {
			if (i < fileObj[0].length) {
				fileArray.push(URL.createObjectURL(fileObj[0][i]))
				filepath.push(fileObj[0][i])
			}
		}
		var data = state1.filter(x => x != null)
		var data1 = state2.filter(x => x != null)
		setState1(data)
		setState2(data1)
		setState1(prevValue => prevValue.concat(fileArray))
		setState2(prevValue => prevValue.concat(filepath))
	}

	const indexDelete = (e, index) => {
		var filteredpreview = state1.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = state2.filter((val, i) => {
			return i !== index;
		});
		setState1(filteredpreview);
		setState2(filteredraw);
	}
	
	

	const validate = () => {
		if (state2?.length == 0) {
			setImageError("This field is required.")
			return false
		}
		return true
	}

	const handleAddService = () => {
		var multipleImage = state2.filter(x => x != null);
		var valid = validate()
		if (valid == true) {
			setTruebutton(true)
			const formData = new FormData();
			{ multipleImage ? multipleImage.forEach((item) => { formData.append("Logo", item) }) : formData.append("Logo", '') }
			axios.post(process.env.REACT_APP_BASE_URL + `ourplatform/addOurPlatform`, formData, { headers: headerformdata })
			.then((res) => {
				setTruebutton(false)
				navigate('/ourpartners');
				setState1([])
				setState2([])
				enqueueSnackbar("Partners added successfully", { variant: 'success' })
			}).catch((err) => {
				setTruebutton(false)
				enqueueSnackbar(err.response && err.response.data.error? err.response.data.error:"Something went wrong.", { variant: 'error' })
				console.log(err, "error");
			})
		}
	}

	return (
		<Box className=''>
			<MiniDrawer type={3} />
			<Box component="section" className="contentWraper">
				<Box className='whiteContainer p-20'>
					<Typography component='h6' className='formTitle'>Add Partner Logo</Typography>
						<Box className=" mb-10">
							<Grid container columnSpacing={2} rowSpacing={1} className=" mb-10">
								<Grid item xs={12} sm={12} md={12}  >
									<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
										{state1.length < 1 &&
											<Button className="multiple_upload_btn" variant="contained" component="label">
												<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
												<input accept={"image/png, image/jpg,image/jpeg,.svg,image/svg+xml"} onChange={multipleImgChange} hidden  type="file" />
											</Button>
										}
									</Box>
								</Grid>
						
								<Grid item xs={12} sm={12} md={12}  >
									{state1.length > 0 &&
										<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
											{state1.length > 0 ? state1.map((x, i) => (
												<Grid item xs={6} sm={4} md={3} lg={2} >
													<Box className='uploaded_img' >
														<Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
														<img src={x} alt='' height={155} width={195} />
													</Box>
												</Grid>
											)) : ""}

											
										</Grid>
									}
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 400px * 400px for better quality picture.</Typography>
								</Grid>
								{imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
							</Grid>
						</Box>

					         {truebutton == false ? 
								<Box className='heading-btns mt-20' style={{ textAlign: 'left' }}>
									<Button onClick={handleAddService} variant="contained" className='theme-btn'>Submit</Button>
								</Box>
                                :
                                <Box className='heading-btns mt-20' style={{ textAlign: 'left' }}>
									<Button variant="contained" className='theme-btn'>Please wait...</Button>
								</Box>}
				</Box>
			</Box>
		</Box>
	)
}