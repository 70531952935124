import './assets/css/Custom.css';
import './assets/css/common.css';
import './assets/css/responsive.css';
import './App.css';
import Routers from './routes';
import { SnackbarProvider } from 'notistack';
import Closebutton from "./components/closebutton"
function App() {
	return (
		<SnackbarProvider maxSnack={3} anchorOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}} action={closeid => <Closebutton value={closeid} />}>
			<Routers />
		</SnackbarProvider>
	);
}

export default App;
