import * as React from 'react';
import Box from '@mui/material/Box';
import { TableBody, Typography, Grid, Paper, Button, TextField, Select, InputAdornment, FormControl, Tooltip, Card, Input, Divider, CardActionArea, Menu } from '@mui/material';
import { PlaylistAddRounded } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DOMPurify from 'dompurify';
import DeleteIcon from '@mui/icons-material/Delete';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack'
import MiniDrawer from '../../../components/drawer'
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import { useRef } from 'react';

const ITEM_HEIGHT = 15;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function FormPropsTextFields() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);
    const [deleteid, setDeleteId] = React.useState('');
    const [openview, setOpenview] = React.useState(false);
    const [alldata, setAlldata] = React.useState('');


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    const handleClose_menu = () => {
        setAnchorEl(null);
    };


    React.useEffect(() => {
        getPartners()
    }, [rowsPerPage, page])

    const getPartners = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `ourplatform/getOurPlatform?size=${rowsPerPage}&page=${page}`, { headers: headerlocal })
            .then((res) => {

                setNewdata(res.data.getourPlatform)
                setTotal(res.data.totalcount)
            }).catch((error) => {
                console.log(error, "error in service get");
            })
    }

    const handleDeletePartners = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `ourplatform/DeleteOurPlatform/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                enqueueSnackbar("Partner deleted successfully", { variant: 'success' })
                getPartners()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleNavigate = () => {
        navigate('/addpartners')
    }



    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };


    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate("/editpartners", { state: val })
    }



    var sanitizedContent = DOMPurify.sanitize(alldata.Description, { ALLOWED_TAGS: [] })


    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }

    const dragItem = useRef(null);
    const dragOverItem = useRef(null);


    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    const drop = async (e) => {
        const copyListItems = [...newdata];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);



        setNewdata(copyListItems);


        var fromvar = [...copyListItems]


        var final = fromvar[dragOverItem.current]


        var before = fromvar[dragOverItem.current - 1]
        var after = fromvar[dragOverItem.current + 1]



        let currElIndexNumber = 0;
        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        } else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }

        var body = {
            index_position: currElIndexNumber
        }
        axios.put(process.env.REACT_APP_BASE_URL + `ourplatform/updateOurPlatformPosition/${final._id}`, body).then((res) => {
            getPartners();
        }).catch((err) =>
            console.log(err)
        )

    };



    return (
        <>
            <Box className=''>
                <MiniDrawer type={3} />
                <Box component="section" className="contentWraper">
                    <Box className='headingBox'>
                        <Typography component='h6' className='formTitle'>Our Partners Logo</Typography>

                        <Button variant="contained" className='theme-btn' onClick={(e) => handleNavigate()}> <PlaylistAddRounded className='fz-18 mr-5' />Add Partner Logo</Button>

                    </Box>

                    <Box className='whiteContainer p-20'>
                        <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList" >
                            {newdata && newdata?.length > 0 ? newdata.map((data, i) => (
                                <Grid item
                                    onDragStart={(e) => dragStart(e, i)}
                                    onDragEnter={(e) => dragEnter(e, i)}
                                    onDragEnd={(e) => drop(e, i)}
                                    key={data._id}
                                    draggable="true"
                                    sx={12} sm={6} md={4} lg={4} xl={3} marginBottom={2} >

                                    <Card className="cardGrid" style={{ cursor: 'pointer' }} >
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={menuopen ? 'long-menu' : undefined}
                                            aria-expanded={menuopen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, data)}
                                            className='vertical_menu'
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={menuopen}
                                            onClose={handleClose_menu}
                                            PaperProps={{
                                                style: {
                                                    width: '15ch',
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

                                        >
                                            <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>
                                            <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>

                                        </Menu>


                                        {/* <Swiper
                                            modules={[Navigation, Pagination]}
                                            className="mySwiper "
                                            slidesPerView={1}
                                            pagination={{
                                                clickable: true,
                                            }}
                                        > */}
                                            {data.Logo?.map((tdata, i) => {
                                                return (
                                                    // <SwiperSlide>
                                                        <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} />
                                                    // </SwiperSlide>
                                                )
                                            })}
                                        {/* </Swiper> */}



                                    </Card>
                                </Grid>
                            )) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                <p className='text-center semibold'>No data found</p></Grid>}
                        </Grid>

                    </Box>

                    <TablePagination
                        rowsPerPageOptions={[8, 12, 16, 20, 24]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='DT-pagination'
                    />
                </Box>
                <Dialog
                    open={open}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    className='custom_modal'
                >
                    <DialogTitle>Would you like to delete partner logo?</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose} className='cancel_btn'>No</Button>
                        <Button onClick={handleDeletePartners} className='theme-btn'>Yes</Button>
                    </DialogActions>
                </Dialog>
            </Box>



        </>
    );
}