
import React, { useRef } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../components/drawer';
// import { header, headerformdata } from "../../../common/headerConstats"
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Card, Input, CardContent, Dialog, Slide } from '@mui/material'
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import 'swiper/css';
import 'swiper/css/pagination';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 40,
	height: 20,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 0,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(20px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 18.5,
		height: 18.5,
	},
	'& .MuiSwitch-track': {
		borderRadius: 18.5 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#784af4',
	}),
	'& .QontoStepIcon-completedIcon': {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
	'& .QontoStepIcon-circle': {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;

	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#E80A89',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#E80A89',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundColor:
			'#E80A89',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	}),
	...(ownerState.completed && {
		backgroundColor:
			'#E80A89',
	}),
}));

function ColorlibStepIcon(props) {
	const { active, completed, className } = props;

	const icons = {
		1: <ListAlt />,
		2: <CompareIcon />,
		3: <Dvr />,
	};
	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};

const steps = ['Basic Information', 'Work Information'];

export default function Service_Steppyform() {


	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}

	let location = useLocation()

	const [activeStep, setActiveStep] = React.useState(0);


	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [category, setCategory] = React.useState({ servicename: location.state.data.service_name, description: location.state.data.description })
	const [recordings, setRecordings] = React.useState([])
	const [truebutton, setTruebutton] = React.useState(false)
	const [error, setError] = React.useState({ servicename: "" })
	const [data, setData] = React.useState([])
	const [categoryId, setCategoryId] = React.useState(location.state.data.category_id)
	const [subcategoryId, setSubCategoryId] = React.useState(location.state.data.subcategory_id ? location.state.data.subcategory_id : "")
	const [catError, setCatError] = React.useState('')
	const [subcatError, setSubCatError] = React.useState('')
	const [imageError, setImageError] = React.useState('')
	const [state1, setState1] = React.useState(location.state.data.additionalimages)
	const [state2, setState2] = React.useState(location.state.data.additionalimages)
	const [existingimages, setExistingimages] = React.useState(location.state.data.additionalimages)
	const [statesingle, setStatesingle] = React.useState([...[], location.state.data.image])
	const [statesingle2, setStatesingle2] = React.useState([...[], location.state.data.image])
	const [statesinglenew, setStatesinglenew] = React.useState([...[], location.state.data.image])
	const [status, setStatus] = React.useState(location.state.data.setService)
	const [additional, setAdditional] = React.useState(location.state.data.additionalinfo)
	const [richeditor, setRichtor] = React.useState(location.state.data.description)
	const [description, setDescription] = React.useState(location.state.data.shortdescription)
	const [imageAddError, setImageAddError] = React.useState('')

	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...additional];
		list[index][name] = value;
		setAdditional(list);
	};

	const handleRemoveClick = index => {
		const list = [...additional];
		list.splice(index, 1);
		setAdditional(list);
	};

	const handleAddClick = () => {
		setAdditional([...additional, { key: '', answer: '', "check": false }]);
	};

	const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
	const dataofeditor = `${richeditor}`

	React.useEffect(() => {

		if (dataofeditor) {
			try {
				const contentState = stateFromHTML(dataofeditor);
				setEditorState(EditorState.createWithContent(contentState));
			} catch (error) {
				console.error("Error during HTML to ContentState conversion:", error);
				//   setEditorState(EditorState.createWithContent(ContentState.createFromText(dataofeditor)));
			}
		}
	}, [dataofeditor]);


	React.useEffect(() => {
		convertContentToHTML();
	}, [editorState]);

	const [convertedContent, setConvertedContent] = React.useState(null);


	const handleEditorChange = (newEditorState) => {
		setEditorState(newEditorState);
	}

	const convertContentToHTML = () => {
		// let currentContentAsHTML = convertToHTML(editorState.getCurrentContent() ? editorState.getCurrentContent() : dataofeditor);
		// setConvertedContent(currentContentAsHTML);
		const currentContent = editorState.getCurrentContent();
		const currentContentAsHTML = stateToHTML(currentContent);
		setConvertedContent(currentContentAsHTML)


	}

	const handleUpdateService = async () => {
		var multipleImage = state2.filter(x => x != null);
		var valid = validate()
		var valid1 = validatenew()
		if (valid == true && valid1 == true) {
			setTruebutton(true)
			const formData = new FormData();
			// formData.append("category_id", categoryId)
			// formData.append("subcategory_id", subcategoryId)
			formData.append("service_name", category.servicename)
			// formData.append("description", category.description)
			formData.append("description", convertedContent)
			formData.append("shortdescription", description)
			formData.append("setService", status)
			{ statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
			{ existingimages ? existingimages.forEach((item) => { formData.append("existingimages", item) }) : formData.append("additionalimages", '') }
			{ multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '') }
			{ additional ? additional.forEach((item) => { formData.append("additionalinfo", JSON.stringify(item)) }) : formData.append("additionalinfo", '') }

			await axios.put(process.env.REACT_APP_BASE_URL + `projects/updateProjects/${location.state.data._id}`, formData, { headers: headerformdata })
				.then((res) => {
					setTruebutton(false)
					navigate('/ourwork');
					setCategory({ servicename: "", description: "" });
					setCategoryId('');
					setSubCategoryId('');
					setState1([])
					setState2([])
					enqueueSnackbar("Our work updated successfully", { variant: 'success' })
				}).catch((err) => {
					setTruebutton(false)
					enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
					console.log(err.response, "error");
				})
		}
	}

	const validate = () => {
		if (!category.servicename ) {
			setError({ servicename: "This field is required." })
			// setCatError("This field is required.")
			// setImageError("Please upload primary Image")
			return false
		}

		if (!category.servicename) {
			setError({ servicename: "This field is required." })
			return false
		}
		// if (!categoryId) {
		// 	setCatError("This field is required.")
		// 	return false
		// }

		// if(statesingle2?.length == 0 || statesinglenew?.length == 0){
		// 	setImageError("Please upload primary Image")
		// 	return false
		// }
		return true
	}

	
	const validatenew = () => {
		if (state2?.length == 0) {
			setImageAddError("This field is required.")
			return false
		}
		return true
	}




	let name, value;
	const handlechange = (e) => {
		name = e.target.name;
		value = e.target.value;
		setCategory({ ...category, [name]: value })
	}

	React.useEffect(() => {
		getCategory()
	}, [])

	React.useEffect(() => {
		getSubCategory()
	}, [categoryId])

	const getCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `category/GetCategoryDropdown?order=asc&isActive=true`, { headers: headerlocal })
			.then((res) => {
				setRecordings(res.data.category)
			}).catch((error) => {
				console.log(error.response, "error");
			})
	}

	const getSubCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Subcategory/getSubcategorydata/${categoryId}?isActive=true&order=asc`, { headers: headerlocal })
			.then((res) => {
				setData(res.data.getsub)
			}).catch((error) => {
				console.log(error.response, "error");
			})
	}

	const multipleImgChangeMultiple = (e, index) => {
		setImageAddError('')
		var fileObj = [];
		var fileArray = [];
		var filepath = [];

		fileObj.push(e.target.files)
		for (let i = 0; i <= fileObj[0].length; i++) {
			if (i < fileObj[0].length) {
				fileArray.push(URL.createObjectURL(fileObj[0][i]))
				filepath.push(fileObj[0][i])
			}
		}
		var data = state1.filter(x => x != null)
		var data1 = state2.filter(x => x != null)
		setState1(data)
		setState2(data1)
		setState1(prevValue => prevValue.concat(fileArray))
		setState2(prevValue => prevValue.concat(filepath))
	}

	const indexDelete = (e, index) => {
		var filteredpreview = state1.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = state2.filter((val, i) => {
			return i !== index;
		});
		setState1(filteredpreview);
		setState2(filteredraw);
	}
	const multipleImgChangeSingle = (e, index) => {
		setImageError('')
		var fileObj = [];
		var fileArray = [];
		var filepath = [];

		fileObj.push(e.target.files)
		for (let i = 0; i <= fileObj[0].length; i++) {
			if (i < fileObj[0].length) {
				fileArray.push(URL.createObjectURL(fileObj[0][i]))
				filepath.push(fileObj[0][i])
			}
		}
		var data = statesingle.filter(x => x != null)
		var data1 = statesingle2.filter(x => x != null)
		setStatesingle(data)
		setStatesingle2(data1)
		setStatesingle(prevValue => prevValue.concat(fileArray))
		setStatesingle2(prevValue => prevValue.concat(filepath))
	}
	const indexDelete1 = (e, index) => {
		var filteredpreview = statesingle.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = statesingle2.filter((val, i) => {
			return i !== index;
		});
		setStatesingle(filteredpreview);
		setStatesingle2(filteredraw);
	}

	const handleNext = () => {
		var valid = validate()
		if (valid === true) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};


	// const hadndleData = (e) => {
	// 	let someArray = additional && additional.filter(item => item.check == false)
	// 	setCategoryId(e.target.value);


	// 	if (e.target.value) {
	// 		axios.get(process.env.REACT_APP_BASE_URL + `category/getCategoryDetails/${e.target.value}`, { headers: headerlocal })
	// 			.then((res) => {
	// 				setAdditional([...someArray, { key: "Category", answer: res.data.CategoryDetails.category_name, check: true }, { key: 'Dated', answer: moment(date).format('DD-MMM-YYYY'), check: true }])
	// 			})
	// 			.catch((err) => {
	// 				console.log(err, "error");
	// 			})

	// 	}
	// 	setCatError('');
	// 	setError({ servicename: '' });
	// 	setCatError('');
	// 	setSubCatError('')
	// }




	return (
		<Box className=''>
			<MiniDrawer type={2} />
			<Box component="section" className="contentWraper">
				<Box className='whiteContainer p-20'>
					<Typography component='h6' className='formTitle'>Edit Our Work</Typography>

					<Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					{activeStep === 0 &&
						<Box className='steppy_content'>
							<Box className="maxw_700">
								<Box className="mb-10">
									<Typography component='div' className='label'>Work Name <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											size="small"
											placeholder="Work Name"
											name='servicename'
											className='textfield'
											style={{ width: '100%' }}
											value={category.servicename}
											onChange={(e) => {
												handlechange(e); setError({ servicename: '' }); setError({ servicename: '' }); setCatError(''); setSubCatError('')
											}}
											InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment>, }}
										/>
										{error.servicename && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.servicename}</p>}
									</FormControl>
								</Box>
								{/* <Box className="mb-10">
									<Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl fullWidth >
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={categoryId ? categoryId : 'sel'}
											onChange={(e) => { hadndleData(e); }}>
										

											<MenuItem disabled value={'sel'}>Select category</MenuItem>
											{recordings && recordings?.length > 0 ? recordings.map((data) => (
												<MenuItem value={data._id}>{data.category_name}</MenuItem>
											))
											:
											<MenuItem disabled>{"No Record's found"}</MenuItem>}
											
											
										</Select>
										{catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
									</FormControl>
								</Box>
								<Box className="mb-10">
									<Typography component='div' className='label'>Select Sub Category </Typography>

									<FormControl fullWidth >
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											disabled={categoryId !== '' ? false : true}
											value={subcategoryId ? subcategoryId : 'sel18'}
											onChange={(e) => {
												setSubCategoryId(e.target.value); setSubCatError(''); setError({ servicename: '' }); setCatError(''); setSubCatError('')
											}}
										>
											<MenuItem disabled value={'sel18'}>Select sub category</MenuItem>
											{data && data?.length > 0 ? data.map((data5) => {
												return (
													<MenuItem value={data5._id}>{data5.subcategory_name}</MenuItem>
												)
											}) :
												<MenuItem disabled>{"No Record's found"}</MenuItem>
											}
										</Select>
										{subcatError && <p style={{ color: 'red' }} className="fz-13 m-0" >{subcatError}</p>}
									</FormControl>
								</Box> */}



								<Grid item xs={12} sm={6} md={6}  >
                                    <Box className="mb-10">
                                        <label className='form-labels ' for="my-input" >Short Description </label>
                                        <FormControl variant="standard" fullWidth>
                                            <textarea
                                                placeholder='Short Description'
                                                className='textarea'
                                                style={{ width: '100%' }}
                                               
                                                defaultValue={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                rows={4}
                                                cols={40} />
                                        </FormControl>
                                    </Box>
                                </Grid>


								<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
									<label className='form-labels ' for="my-input" >Primary Image<Typography component='span' className='star'> * </Typography></label>
									{statesingle && statesingle.length === 0 &&
										<>
											<label htmlFor="contained-button-file1" className="upload_btn">
												<Box className='' >
													<Input
														inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
														onChange={multipleImgChangeSingle}
														style={{ display: "none", }}
														id="contained-button-file1"
														type="file"
													/>
													<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
												</Box>
											</label>
										</>
									}
								</Box>
								{imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}



								{statesingle && statesingle.length > 0 && statesingle.map((x, i) => (
									<Box className='uploaded_img mb-10' >
										<Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
										<img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
									</Box>
								))}

								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 500px for better quality picture.</Typography>
								</Grid>
								{/* {statesinglenew && statesinglenew.length > 0 && statesinglenew.map((x, i) => (
									<Box className='uploaded_img mb-10' >
										<Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
										<img src={process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
									</Box>
								))} */}





								<Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
									<Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
								</Box>

							</Box>
						</Box >
					}

					{
						activeStep === 1 &&
						<Box className="steppy_content mb-10">
							<Grid container columnSpacing={1} rowSpacing={1} className=" mb-10">
								<Grid item xs={12} sm={12} md={12}  >
									<Box className=" ">
										<label className='form-labels ' for="my-input" >Slider Images <Typography component='span' className='star'> * </Typography></label>
										{state1.length < 1 &&
											<Button className="multiple_upload_btn" variant="contained" component="label">
												<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
												<input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChangeMultiple} hidden multiple type="file" />
											</Button>
										}
									</Box>
								</Grid>


								{imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0 ml-20" >{imageAddError}</p>}

								<Grid item xs={12} sm={12} md={12}  >
									{state1.length > 0 &&
										<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container">
											{state1.length > 0 ? state1.map((x, i) => (
												<Grid item xs={6} sm={4} md={3} lg={2} >
													<Box className='uploaded_img' >
														<Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
														<img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
													</Box>
												</Grid>
											)) : ""}
											<Grid item xs={6} sm={4} md={3} lg={2} >
												<label htmlFor="contained-button-file1" className="w-full">
													<Box className='multiple_upload_btn' >
														<Input
															inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
															onChange={multipleImgChangeMultiple}
															style={{ display: "none", }}
															id="contained-button-file1"
															type="file"
														/>
														<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
													</Box>
												</label>
											</Grid>
										</Grid>
									}
								</Grid>

								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
								</Grid>
								{/* <Grid item xs={12} sm={12} md={12}  >

									<Box className="mb-10">
										<Typography component='div' className='label'>Description <Typography component='span' className='star'></Typography></Typography>

										<FormControl variant="standard" fullWidth>
											<textarea
												className='textarea'
												style={{ width: '100%' }}
												name="description"
												defaultValue={category.description}
												onChange={(e) => handlechange(e)}
												rows={4}
												cols={40} />

										</FormControl>
									</Box>
								</Grid> */}

								<Grid item xs={12} sm={12} md={6} lg={6} className='mb-10'>
									<Typography component='div' className='label'>Description </Typography>
									<div className="editorclass">
										<Editor
											editorState={editorState}
											onEditorStateChange={handleEditorChange}
											toolbarClassName="toolbar"
											wrapperClassName="wrapperClassName"
											editorClassName="editorcontent"
											
                                            toolbar={{
                                                options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
                                                inline: {
                                                  options: ['bold', 'italic', 'underline'],
                                                },
                                                blockType: {
                                                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                },
                                                list: {
                                                  options: ['unordered', 'ordered'],
                                                },
                                                textAlign: {
                                                  options: ['left', 'center', 'right', 'justify'],
                                                },
                                                link: {
                                                  options: ['link'],
                                                },
                                                emoji: {
                                                  options: [
                                                    '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
                                                    '😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
                                                  ],
                                                },
                                                image: {
                                                  uploadEnabled: true,
                                                  previewImage: true,
                                                  inputAccept: 'image/*',
                                                  alt: { present: false, mandatory: false },
                                                },
                                              }}
										// toolbar={{
										//     options: ['inline', 'list', 'textAlign', 'link', 'history'],
										//     inline: { inDropdown: true },
										//     list: { inDropdown: true },
										//     textAlign: { inDropdown: true },
										//     link: { inDropdown: true },
										//     history: { inDropdown: true },
										// }}
										/>


									</div>
								</Grid>



								<Grid item xs={12} sm={12} md={12}  >

									<Typography component='div' className='label'>Set as Primary </Typography>

									<FormControlLabel control={<IOSSwitch  checked={status} onChange={() => setStatus(!status)} sx={{ m: 1 }} />} />
								</Grid>
								{/* <Grid item xs={12} sm={12} md={12}  >
									<label className='form-labels' for="my-input">Additional Information</label>
								</Grid> */}

								{/* {additional ? additional.map((x, i) => (
									<>
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<Box style={{ width: '100%', marginRight: '20px', marginTop: 2 }} className='checkbox'>
												<label className='form-labels' for="my-input">Cue Word {i + 1}</label>

												<TextField fullWidth size="small"
													className='textfield'
													name="key" value={x.key}
													onChange={e => handleInputChange(e, i)}
													// disabled={x.check == true ? true : false}
													type="text" id="quest-TF " />
											</Box>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<label className='form-labels ' for="my-input">Answer</label>
											<Box className='pos_rel'>

												<TextField fullWidth size="small" className='textfield'
													// disabled={x.check == true ? true : false}


													name="answer" value={x.answer} onChange={e => handleInputChange(e, i)}
													type="text" id="quest-TF " />

												{x.check != true &&
													<Box className='closeIcon_grid'>
														<Button style={{ minWidth: 40 }} onClick={() => handleRemoveClick(i)} className="theme-btn"
														>x</Button>
													</Box>}
											</Box>

										</Grid>

										<Grid item xs={12} sm={12} md={12}  >
											{additional.length - 1 === i && <Box className=""><Button className='theme-btn' onClick={handleAddClick} >Add</Button></Box>}
										</Grid>
									</>)) : ""}

									<Grid item xs={12} sm={12} md={12}  >
											{additional.length  === 0 && <Box className=""><Button className='theme-btn' onClick={handleAddClick} >Add</Button></Box>}
										</Grid> */}

							</Grid>
						</Box>


					}


					<>
						{activeStep === 1 && truebutton === false ?
							<>
								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
									<Button onClick={handleUpdateService} variant="contained" className='theme-btn'>Update</Button>
								</Box>
							</>
							:
							activeStep === 1 && truebutton === true &&
							<>
								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
									<Button variant="contained" className='theme-btn  mr-10'>Please wait...</Button>
								</Box>

							</>
						}
					</>


				</Box>
			</Box>
		</Box>
	)
}

