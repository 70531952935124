import * as React from 'react';
import Box from '@mui/material/Box';
import { TableBody, Typography, Grid, Paper, Button, TextField, Select, InputAdornment, FormControl, Tooltip, Card, Input, Divider, CardActionArea, Menu } from '@mui/material';
import { PlaylistAddRounded, InfoOutlined, DataArraySharp, EditOutlined, Close, Add, SearchOutlined, GridView, ListAlt, CheckCircle, Verified } from '@mui/icons-material';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import TableHead from '@mui/material/TableHead';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Checkbox from '@mui/material/Checkbox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DOMPurify from 'dompurify';
import Sheet from "@mui/joy/Sheet";
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
// import './style.css'
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack'
import MiniDrawer from '../../../components/drawer'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const ITEM_HEIGHT = 15;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(24px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function FormPropsTextFields() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    console.log(newdata, "newdata");
    const [open, setOpen] = React.useState(false);
    const [openview, setOpenview] = React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);

    const [htmlContent, setHtmlContent] = React.useState();
    const [alldata, setAlldata] = React.useState('');
    const [cat, setCat] = React.useState([]);
    const [view, setView] = React.useState(false);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    React.useEffect(() => {
        getCategory()
    }, [])

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `category/GetCategory?size=100&page=0&order=asc`, { headers: headerlocal })
            .then((res) => {
                setCat(res.data.category)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }

    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    const handleClose_menu = () => {
        setAnchorEl(null);
    };

    const [deleteid, setDeleteId] = React.useState('');
    const [catid, setCatId] = React.useState('all');

    React.useEffect(() => {
        getService()
    }, [rowsPerPage, page, catid, search])

    const getService = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `service/GetServicewithcategory?size=${rowsPerPage}&page=${page}&search=${search}&category_id=${catid}`, { headers: headerlocal })
            .then((res) => {
                setNewdata(res.data.service)
                setTotal(res.data.totalcount)
            }).catch((error) => {
                console.log(error, "error in service get");
            })
    }

    const handleDeleteService = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `service/DeleteService/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                enqueueSnackbar("Service deleted successfully", { variant: 'success' })
                getService()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleNavigate = () => {
        navigate('/servicesteps')
    }



    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };


    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate("/editservice", { state: val })
    }

    const handleView = () => {
        setAnchorEl(null);
        setOpenview(true)

    }




    const handleRemove = () => {
        setCatId('all')
    }


    const handleChangeCheck = async (e, data) => {
        var body = {
            setService: e.target.checked,
        }
        await axios.put(process.env.REACT_APP_BASE_URL + `service/EditsetService/${data._id}`, body, { headers: headerlocal })
            .then((res) => {
                getService();
            }).catch((err => {
                enqueueSnackbar(err.response.data.error, { variant: 'error' })
                console.log(err.response.data.error, "error while shop enable");
            }))
    }

    const sanitizedContent = DOMPurify.sanitize(alldata.description, { ALLOWED_TAGS: [] });

    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }

    const Gridview = () => {
        setView(false)
    }
    const Listview = () => {
        setView(true)
    }
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(newdata);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        var fromvar = [...items]
        var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;

        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        } else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }
        setNewdata(fromvar);

        var body = {
            index_position: currElIndexNumber
        }
        axios.put(process.env.REACT_APP_BASE_URL + `service/updateServiceposition/${final._id}`, body, { headers: headerlocal })
            .then((res) => {
                console.log(res.data, "drag response");
                getService();
            }).catch((err) =>
                console.log(err.response, "drag & drop")
            )
    }
    return (
        <>
            <Box className=''>
                <MiniDrawer type={0} />
                <Box component="section" className="contentWraper">
                    <Box className='headingBox'>
                        {/* <Typography style={{fontWeight:"bold"}} noWrap className='semibold title' variant="h1" component="h1">Service</Typography> */}
                        <Typography component='h6' className='formTitle'>Service</Typography>
                        <Box className='d-flex align-items-center'>
                            <Box className="mr-10">
                                <Tooltip title="Grid View"><Button className={view == false ? 'active_tab' : 'inactive_tab'} onClick={Gridview}><GridView /></Button></Tooltip>
                                <Tooltip title="List View"> <Button className={view == true ? 'active_tab' : 'inactive_tab'} onClick={Listview}><ListAlt /></Button></Tooltip>
                            </Box>
                            <Button variant="contained" className='theme-btn' onClick={(e) => handleNavigate()}> <Add className='fz-18 mr-5' />Add Service</Button>

                        </Box>
                    </Box>


                    {view == false ?
                        <Box>
                            <Grid container rowSpacing={2} columnSpacing={2} style={{ marginBottom: 10 }} className='align-items-end'>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormControl variant="standard" fullWidth>
                                        <Typography component="div" className="label">Category</Typography>
                                        <FormControl fullWidth >
                                            <Select className="select"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={(e) => setCatId(e.target.value)}
                                                value={catid ? catid : 'all'}
                                            >
                                                <MenuItem value={'all'}>All</MenuItem>
                                                {cat && cat.length > 0 && cat.map((data) => (
                                                    <MenuItem value={data._id}>{data.category_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormControl variant="standard" fullWidth>
                                        <FormControl fullWidth >
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                value={search}
                                                className='textfield search '
                                                onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                                                placeholder='Search Here...'
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </FormControl>
                                </Grid>
                                {catid === 'all' ? "" :
                                    <Grid item xs={12} sm={4} md={2} lg={2} className='d-flex align-items-end mb-5'>
                                        <Button onClick={handleRemove} className='theme-btn'>Reset</Button>
                                    </Grid>}
                            </Grid>

                            <Box className='whiteContainer p-20'>
                                <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList" >
                                    {/* <Typography noWrap className='semibold title' style={{margin:10}} variant="h6" component="h6">Service List</Typography> */}
                                    {newdata && newdata.length > 0 ? newdata.map((data, index) => {
                                    var sanitizedContent = DOMPurify.sanitize(data.description, { ALLOWED_TAGS: [] })
                                    
                                    
                                    return (
                                       
                                        <Grid key={index} item xs={12} sm={6} md={4} lg={3} sx={{ marginTop: 2 }}>
                                            <Card className="cardGrid" >

                                                <IconButton
                                                    aria-label="more"
                                                    id="long-button"
                                                    aria-controls={menuopen ? 'long-menu' : undefined}
                                                    aria-expanded={menuopen ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleClick(e, data)}
                                                    className='vertical_menu'
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'long-button',
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={menuopen}
                                                    onClose={handleClose_menu}
                                                    PaperProps={{
                                                        style: {
                                                            width: '15ch',
                                                        },
                                                    }}
                                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

                                                >
                                                    <MenuItem className='menuitems' onClick={() => handleView()}><VisibilityIcon className='fz-16 mr-5' />View</MenuItem>
                                                    <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>
                                                    <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>
                                                </Menu>
                                                <div className="carosuel">
                                                    {/* <Carousel autoPlay={true} dynamicHeight={false} interval={3000} showStatus={false}
                                                showThumbs={false} showIndicators={true}  > */}

                                                    <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${data.image}`} />
                                                    {/* {data.additionalimages.map((tdata, i) => {
                                                    return (
                                                        <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata.path}`} />
                                                    )
                                                })} </Carousel> */}
                                                </div>



                                                {/* <TableCell component="th" scope="row" padding="none" className='DT-row'  >
                                            <Checkbox onChange={(e) => handleChangeCheck(e, row)} checked={row.setCategory} />
                                        </TableCell>


                                        <TableCell component="th" scope="row" padding="none" className='DT-row'  >
                                            <FormControlLabel onChange={(e) => handleChangeStatus(e, row)} checked={row.isActive} control={<IOSSwitch sx={{ m: 1 }} />} />
                                        </TableCell> */}

                                                <CardContent>
                                                <Box className='mb-10 d-flex align-items-center justify-content-between'>
                                                        <Typography noWrap className='semibold title' variant="h6" component="h6">{data.service_name}</Typography>
                                                       {data.setService == true ? <Typography component='div' variant='body1' className='fz-14 '><Verified className='fz-18' style={{color:'#1492fd'}} /></Typography>:""}
                                                    </Box>
                                                    <Box className='mb-10'>
                                                        <Typography component='span' variant='body1' className='fz-14 semibold'>Category: </Typography>
                                                        <Typography component='span' variant='body1' className='fz-14 '>{data && data.category && data.category?.length>0 && data.category[0].category_name}</Typography>
                                                    </Box>
                                                    {data.description ? 
                                  
                                                     <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                                                    
                                                    // <Typography className='p_tag ellipse_3' component='p'>{data.description}</Typography>
                                                        :
                                                        <Typography className='p18_tag' >There is no short description</Typography>}
                                                    {/* <Typography noWrap className='semibold title fz-14' variant="h6" component="span">Set as Primary</Typography>
                                            <Checkbox onChange={(e) => handleChangeCheck(e, data)} checked={data && data.setService} /> */}

                                                </CardContent>



                                            </Card>
                                        </Grid>
                                    )}) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <p className='text-center semibold'>No data found</p></Grid>}
                                </Grid>
                                <Dialog open={open} keepMounted
                                    onClose={handleClose}
                                    aria-describedby="alert-dialog-slide-description"
                                    className='custom_modal'>
                                    <DialogTitle>Would you like to delete Service?</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={handleClose} className='cancel_btn'>No</Button>
                                        <Button onClick={handleDeleteService} className='theme-btn'>Yes</Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                className='DT-pagination'
                            />
                        </Box>
                        :
                        <Box>
                            <TableContainer component={Paper} id='table'>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>
                                    <TableHead className='DT-head'>
                                        <TableRow className=''>
                                            <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px' }}>Drag row</TableCell>
                                            <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Service name</TableCell>
                                            <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Category</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <DragDropContext onDragEnd={handleOnDragEnd} >
                                        <Droppable droppableId="characters">
                                            {(provided) => (

                                                <TableBody className="characters "   {...provided.droppableProps} ref={provided.innerRef}>
                                                    {newdata && newdata?.length > 0 ? newdata.map((row, i) => (


                                                        <Draggable key={row._id} draggableId={row._id} index={i}>
                                                            {(provided) => (
                                                                <>
                                                                    <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className=''>
                                                                        <TableCell className='DT-row' style={{ width: 200 }} align="left"><DragHandleIcon /></TableCell >
                                                                        {row && row.service_name ? <TableCell className='DT-row' align="left" style={{ minWidth: 200 }}>{row && row.service_name}</TableCell > : <TableCell className='DT-row' style={{ minWidth: 200 }}>-</TableCell >}

                                                                        <TableCell className='DT-row' align="left" style={{ minWidth: 100 }}>{row && row.category && row.category?.length > 0 && row.category[0].category_name} </TableCell >

                                                                    </TableRow>
                                                                </>
                                                            )}
                                                        </Draggable>
                                                    )) : <TableRow className=''>
                                                        <TableCell className='DT-row' align="center" colSpan={5}>
                                                            No Data Found
                                     </TableCell >
                                                    </TableRow>}
                                                </TableBody>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Table>
                            </TableContainer>

                        </Box>}
                </Box>

            </Box>

            <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className='custom_modal'
                maxWidth='md'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Services Details
        </DialogTitle>
                {/* <IconButton
                    aria-label="close"
                    onClick={handleClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton> */}
                <DialogContent dividers>
                    <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="carosuel mb-10">
                                <Carousel autoPlay={false} dynamicHeight={false} infiniteLoop={true} interval={3000} showStatus={false}
                                    showThumbs={false} showIndicators={true}  >

                                    {alldata && alldata.additionalimages && alldata.additionalimages?.length > 0 && alldata.additionalimages.map((tdata, i) => {
                                        return (
                                            <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} />
                                        )
                                    })} </Carousel>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>

                            <Box className='mb-10'>
                                <Typography component='span' variant='body1' className='fz-14 semibold'>Service Name: </Typography>
                                <Typography component='span' variant='body1' className='fz-14 '>{alldata.service_name}</Typography>
                            </Box>

                            <Box className=''>
                                <Typography component='span' variant='body1' className='fz-14 semibold'>Category Name: </Typography>
                                <Typography component='span' variant='body1' className='fz-14 '>{alldata.category && alldata.category?.length > 0 && alldata.category[0].category_name}</Typography>
                            </Box>
                            <Box className=''>
                                <Typography component='span' variant='body1' className='fz-14 semibold'>Sub-Category Name: </Typography>
                                <Typography component='span' variant='body1' className='fz-14 '>{alldata.subcategory && alldata.subcategory?.length > 0 && alldata.subcategory[0].subcategory_name}</Typography>
                            </Box>
                            <Box className="my-30">
                                <Typography component='span' variant='body1' className='fz-18 semibold '>Additional Information</Typography>
                                <Grid container columnSpacing={2} rowSpacing={2} className="" >
                                    <Grid item xs={12} sm={12} md={12}  >

                                        {alldata.additionalinfo?.length > 0 && alldata.additionalinfo.map((d) => (
                                            <>
                                                <Box className='mb-10'>
                                                    <Typography component='div' variant='body1' className='fz-14 semibold'>{d.key} </Typography>
                                                    <Typography component='div' variant='body1' className='fz-14 '>{d.answer}</Typography>
                                                </Box>
                                            </>
                                        ))}


                                    </Grid>

                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                   


                    {/* <Typography component='p' variant='body1' className='fz-14 semibold'>{alldata.category[0].category_name}</Typography> */}
                    <Typography component='div' variant='body1' className='fz-14 semibold'>Description: </Typography>
                    {alldata.description? <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                    : <Typography className='p18_tag' >There is no short description</Typography>}

                </DialogContent>
                <DialogActions>
                    <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>


        </>
    );
}