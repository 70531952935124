import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Button, Collapse, Menu, MenuItem, SwipeableDrawer } from '@mui/material';
import { ArrowDropDown, AutoFixHigh, BusinessOutlined, ExpandLess, ExpandMore, HomeOutlined, HomeRounded, MenuOpen, PersonAddAlt1Rounded, PowerSettingsNew, Settings, SettingsRounded, Share, SmsFailed, SmsFailedRounded,ContactMail, PictureAsPdf } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import LanIcon from '@mui/icons-material/Lan';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { useCookies } from 'react-cookie';
import WorkIcon from '@mui/icons-material/Work';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CollectionsIcon from '@mui/icons-material/Collections';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';

const drawerWidth = 260;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	backgroundColor: '#FFCDCA',
	color: 'white'
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
	backgroundColor: '#FFCDCA',
	color: 'white'
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `100%`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	backgroundColor: '#fff'

}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),

		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),

	}),
);


const sidebar_Items = [
	// {
	// 	name: 'Dashboard',
	// 	icon: <HomeRounded className='sidebarIcons' style={{}} />,
	// 	link: '/dashboard'
	// },
	{
		name: 'Service',
		icon: <LanIcon className='sidebarIcons' style={{}} />,
		link: '/service'
	},
	{
		name: 'Category',
		icon: <BusinessOutlined className='sidebarIcons' style={{}} />,
		link: '/category'
	},
	// {
	// 	name: 'Sub Category',
	// 	icon: <SmsFailedRounded className='sidebarIcons' style={{}} />,
	// 	link: '/subcategory'
	// },



	{
		name: 'Our Work',
		icon: <WorkIcon className='sidebarIcons' style={{}} />,
		link: '/ourwork'
	},

	

	{
		name: 'Our Partners Logo',
		icon: <HandshakeTwoToneIcon className='sidebarIcons' style={{}} />,
		link: '/ourpartners'
	},

	// {
	// 	name: 'Blogs',
	// 	icon: <BookIcon className='sidebarIcons' style={{}} />,
	// 	link: '/blogs'
	// },
	{
		name: 'Gallery',
		icon: <CollectionsIcon className='sidebarIcons' style={{}} />,
		link: '/gallery'
	},

	// {
	// 	name: 'Team Members',
	// 	icon: <Diversity3Icon className='sidebarIcons' style={{}} />,
	// 	link: '/team'
	// },

	{
		name: 'Settings',
		icon: <SettingsRounded className='sidebarIcons' style={{}} />,
		submenu: [
			// {
			// 	name: "Template Settings",
			// 	link: '/Settings',
			// 	order: 1,
			// 	icon: <AutoFixHigh className='sidebarIcons' style={{}} />,

			// },
			{
				name: "Brochure",
				link: '/brochure',
				order: 1,
				icon: <PictureAsPdf className='sidebarIcons' style={{}} />,

			},
			{
				name: "Enquiry",
				link: '/enquiry',
				order: 2,
				icon: <AssignmentLateIcon  className='sidebarIcons' style={{}} />,


			},
			{
				name: "Career",
				link: '/career',
				order:3,
				icon: <ContactMail  className='sidebarIcons' style={{}} />,


			},
			{
				name: "Contact Us",
				link: '/contatus',
				order:4,
				icon: <ContactMail  className='sidebarIcons' style={{}} />,


			},

		]
	},

]

export default function MiniDrawer({ type }) {

	const [open, setOpen] = React.useState(true);
	const [submenuopen, setSubmenuopen] = React.useState(false);

	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});
	let navigate = useNavigate()
	const [cookies, setCookie, removeCookie] = useCookies(['value'])
	const submenu_handleClick = () => {
		setSubmenuopen(!submenuopen);
	};
	const handleLogout = () => {
		removeCookie("value")
		localStorage.removeItem("userData");
		localStorage.removeItem("token");

		navigate("/")
	}

	const handleDrawerToggle = () => {
		setOpen(!open);
		document.querySelector('body').classList.toggle("sidebarclose");
	};

	const PageNavigation = (url) => {
		// window.history.pushState("", "", url);
		navigate(url);
		// document.querySelector('body').classList.remove("sidebarclose");

	}
		const handleNavigate = (data) => {
		switch (data.name) {
			case "Service":
				navigate("/service")
				localStorage.removeItem('submenu');
				setSubmenuopen(false)
				break;
			case "Category":
				navigate("/category")
				localStorage.removeItem('submenu');
				setSubmenuopen(false)
				break;
			// case "Sub Category":
			// 	navigate("/subcategory")
			// 	localStorage.removeItem('submenu');
			// 	setSubmenuopen(false)
			// 	break;
		
			case "Team Members":
				navigate("/team")
				setSubmenuopen(false)
				localStorage.removeItem('submenu');
				break;
			// case "Blogs":
			// 	navigate("/blogs")
			// 	setSubmenuopen(false)
			// 	localStorage.removeItem('submenu');
			// 	break;
		   case "Our Work":
				navigate("/ourwork")
				setSubmenuopen(false)
				localStorage.removeItem('submenu');
				break;
	


				
			case "Our Partners Logo":
					navigate("/ourpartners")
					setSubmenuopen(false)
					localStorage.removeItem('submenu');
					break;
			case "Gallery":
						navigate("/gallery")
						setSubmenuopen(false)
						localStorage.removeItem('submenu');
						break;
				

				
					
			case "Enquiry":
				navigate("/enquiry")
				setSubmenuopen(true)
				localStorage.setItem('submenu', 'Open');
				break;
			case "Brochure":
					navigate("/brochure")
					setSubmenuopen(true)
					localStorage.setItem('submenu', 'Open');
					break;
			case "Career":
				navigate("/career")
				setSubmenuopen(true)
				localStorage.setItem('submenu', 'Open');
				break;
			case "Contact Us":
				navigate("/contatus")
				setSubmenuopen(true)
				localStorage.setItem('submenu', 'Open');
				break;
			default:
				setSubmenuopen(false); // Close submenu for any other cases
				break;
		}
	}
	const [selectedsubIndex, setSelectedsubIndex] = React.useState(0);
	React.useEffect(() => {
		const submenuState = localStorage.getItem('submenu');
		if (submenuState === 'Open') {
			setSubmenuopen(true);
		}

		const submenu=JSON.parse(localStorage.getItem('subindex'))
		if(submenu){
			setSelectedsubIndex(submenu)
		}
	}, []);
	// ----responsive drawer------
	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const list = (anchor) => (
		<Box
			sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
			role="presentation"
			// onClick={toggleDrawer(anchor, false)}
			// onKeyDown={toggleDrawer(anchor, false)}
			style={{ paddingTop: 60 }}
		>
			<List>
			{sidebar_Items.map((text, index) => (
						<ListItem key={index} disablePadding sx={{ display: 'block' }} selected={selectedIndex === index}>
							{text.submenu ?


								<>
									<ListItemButton
										sx={{
											minHeight: 44,
											justifyContent: open ? 'initial' : 'center',
											px: 2.5,
											fontSize: 13,
											paddingLeft: 4
										}}
										onClick={(event) => { handleListItemClick(event, index,); submenu_handleClick()}}
										// onClick={submenu_handleClick}
										>
										<ListItemIcon sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
											color: 'white',
											marginRight: 2,
										}}>
											<SettingsRounded className='sidebarIcons' />
										</ListItemIcon>
										<ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} className='sidebar_itemText'  />
										{submenuopen ? <ExpandLess className='sidebarIcons' /> : <ExpandMore className='sidebarIcons' />}
									</ListItemButton>
									<Collapse in={submenuopen} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											{(text.submenu !== undefined) ?
												text.submenu.map((data, index) => {
									
													return (
														data &&
														<ListItemButton sx={{
															minHeight: 44,
															justifyContent: open ? 'initial' : 'center',
															px: 2.5,
															fontSize: 13,
															paddingLeft: 4,
														}}
															key={index}
															selected={selectedsubIndex === index}
															onClick={(event) => { handleNavigate(data) ;handleListsubItemClick(index)}}
															>
															<ListItemIcon sx={{
																minWidth: 0,
																mr: open ? 3 : 'auto',
																justifyContent: 'center',
																color: 'white',
																marginRight: 2,
															}}
																className='sidebarIcons'
															>
																{data.icon}
															</ListItemIcon>
															<ListItemText primary={data.name} sx={{ opacity: open ? 1 : 0 }}  className='sidebar_itemText' />
														</ListItemButton>
													)
												}) : null}
										</List>
									</Collapse>
								</>
								:
								<ListItemButton
									sx={{
										minHeight: 44,
										justifyContent: open ? 'initial' : 'center',
										px: 2.5,
										fontSize: 13,
										paddingLeft: 4
									}}
									onClick={(event) => {  handleNavigate(text) }}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
											color: 'white',
											marginRight: 2,
										}}

									>
										{text.icon}
									</ListItemIcon>
									<ListItemText primary={text.name} sx={{ opacity: open ? 1 : 0 }}  className='sidebar_itemText' />
								</ListItemButton>
							}
						</ListItem>
					))}
			</List>
		</Box>
	);

	const auth = JSON.parse(localStorage.getItem("userData"))

	const [firstLetter] = auth && auth.email.toUpperCase();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openAcct = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const handleListItemClick = (event, index) => {

		setSelectedIndex(index);

	};
	const handleListsubItemClick = (index) => {
		localStorage.setItem('subindex', index );
		// setSelectedsubIndex(index);
	};

	React.useState(() => {
		setSelectedIndex(type);
		// setSelectedsubIndex(type);
	}, [type,selectedsubIndex])


	const showSettingsMenu = () => {
		document.querySelector('.settings').classList.toggle("showSettingMenu");

	}
	const showAccountMenu = () => {
		document.querySelector('body').classList.toggle("showAccountMenu");
	}


	return (

		<Box sx={{ display: 'flex', minHeight: 64 }}>
			<AppBar position="fixed" open={open} className='appbar'>
				<Toolbar className='toolbar'>
					<Box className='logo'>
						<img src={require('./../assets/images/logo.png')} width='100%' height='auto'></img>
					</Box>
					<Box className='account'>					
						<IconButton
							onClick={handleClick}
							size="small"
							sx={{ ml: 2 }}
							aria-controls={open ? 'account-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							disableRipple={true}
						>
							<Avatar sx={{ width: 32, height: 32, marginRight: 1 }}>{firstLetter ? firstLetter :'R'}</Avatar>
							{/* <Typography component='div' variant='body1' className='fz-13 name'>{auth && auth.email}</Typography> */}
							<ArrowDropDown sx={{ marginTop: -0.4, fontSize: 18, color: '#222' }} />
						</IconButton>						
					</Box>
					{/* <Menu
						className='acct-menu'
						anchorEl={anchorEl}
						id="account-menu"
						open={openAcct}
						onClose={handleClose}
						onClick={handleClose}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								'& .MuiSvgIcon-root': {
									width: 16,
									height: 16,
									ml: -0.5,
									mr: 1,
									color: '#333333'
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						<MenuItem className='fz-14'>
							<PersonAddAlt1Rounded className='fz-16' />My Profile
						</MenuItem>
						<MenuItem className='fz-14'>
							<SettingsRounded className='fz-16' /> Account Settings
						</MenuItem>
						<MenuItem className='fz-14'>
							<PowerSettingsNew className='fz-16' /> Logout
						</MenuItem>


					</Menu> */}
					<IconButton
						aria-label="open drawer"
						edge="end"
						onClick={() => showAccountMenu()}
						// sx={{ ...(open && { display: 'none' }) }}
						className='Res_AccountIcon'
					>
						<Share />

					</IconButton>
					


					

					<Box className='res-menu-icon'>
						{['left'].map((anchor) => (

							<React.Fragment key={anchor}>
								<IconButton
									color="inherit"
									aria-label="open drawer"
									edge="end"
									onClick={toggleDrawer(anchor, true)}
									// sx={{ ...(open && { display: 'none' }) }}
									className=''
								>
									<MenuIcon />
								</IconButton>
								<SwipeableDrawer
									anchor={anchor}
									open={state[anchor]}
									onClose={toggleDrawer(anchor, false)}
									onOpen={toggleDrawer(anchor, true)}
									className='res-drawer'

								>
									{list(anchor)}
								</SwipeableDrawer>
							</React.Fragment>
						))}
					</Box>
				</Toolbar>
				<Box className='resAccountmenu'>
					<Box className='account'>
						<IconButton
							onClick={handleClick}
							size="small"
							sx={{ ml: 2 }}
							aria-controls={open ? 'account-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							disableRipple={true}
						>
							{/* <Avatar sx={{ width: 32, height: 32, marginRight: 1 }}>M</Avatar>
							<Typography component='div' variant='body1' className='fz-13 name'> Super Admin</Typography> */}
							<ArrowDropDown sx={{ marginTop: -0.4, fontSize: 18, color: '#222' }} />
						</IconButton>
					</Box>
					<Menu
						className='acct-menu'
						anchorEl={anchorEl}
						id="account-menu"
						open={openAcct}
						onClose={handleClose}
						// onClick={handleLogout}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								'& .MuiSvgIcon-root': {
									width: 18,
									height: 18,
									ml: -0.5,
									mr: 1,
									color: '#333333',
									mt: -0.2
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						{/* <MenuItem className='fz-14'>
							<PersonAddAlt1Rounded className='fz-16' />My Profile
						</MenuItem>
					 */}
					 
						<MenuItem onClick={handleLogout} className='fz-14'>
							<PowerSettingsNew className='fz-16' /> Logout
						</MenuItem>


					</Menu>

				</Box>
			</AppBar>

			<Drawer variant="permanent" open={open} className='Custom_drawer'>
				
				<Box className={open ? 'menuicon-box' : 'menuIcon-boxClosed'}>
					
					<IconButton onClick={handleDrawerToggle} className='ml-auto'>

						<MenuOpen className='menuicon' />
					</IconButton>
				</Box>
				<List>
					{sidebar_Items.map((text, index) => (
						<ListItem key={index} disablePadding sx={{ display: 'block' }} selected={selectedIndex === index}>
							{text.submenu ?


								<>
									<ListItemButton
										sx={{
											minHeight: 44,
											justifyContent: open ? 'initial' : 'center',
											px: 2.5,
											fontSize: 13,
											paddingLeft: 4
										}}
										onClick={(event) => { handleListItemClick(event, index,); submenu_handleClick()}}
										// onClick={submenu_handleClick}
										>
										<ListItemIcon sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
											color: 'white',
											marginRight: 2,
										}}>
											<SettingsRounded className='sidebarIcons' />
										</ListItemIcon>
										<ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} className='sidebar_itemText'  />
										{submenuopen ? <ExpandLess className='sidebarIcons' /> : <ExpandMore className='sidebarIcons' />}
									</ListItemButton>
									<Collapse in={submenuopen} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											{(text.submenu !== undefined) ?
												text.submenu.map((data, index) => {
									
													return (
														data &&
														<ListItemButton sx={{
															minHeight: 44,
															justifyContent: open ? 'initial' : 'center',
															px: 2.5,
															fontSize: 13,
															paddingLeft: 4,
														}}
															key={index}
															selected={selectedsubIndex === index}
															onClick={(event) => { handleNavigate(data) ;handleListsubItemClick(index)}}
															>
															<ListItemIcon sx={{
																minWidth: 0,
																mr: open ? 3 : 'auto',
																justifyContent: 'center',
																color: 'white',
																marginRight: 2,
															}}
																className='sidebarIcons'
															>
																{data.icon}
															</ListItemIcon>
															<ListItemText primary={data.name} sx={{ opacity: open ? 1 : 0 }}  className='sidebar_itemText' />
														</ListItemButton>
													)
												}) : null}
										</List>
									</Collapse>
								</>
								:
								<ListItemButton
									sx={{
										minHeight: 44,
										justifyContent: open ? 'initial' : 'center',
										px: 2.5,
										fontSize: 13,
										paddingLeft: 4
									}}
									onClick={(event) => {  handleNavigate(text) }}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
											color: 'white',
											marginRight: 2,
										}}

									>
										{text.icon}
									</ListItemIcon>
									<ListItemText primary={text.name} sx={{ opacity: open ? 1 : 0 }}  className='sidebar_itemText' />
								</ListItemButton>
							}
						</ListItem>
					))}
				</List>

			</Drawer>
			<DrawerHeader />
		</Box >
	);
}
